import { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { RichTextEditor } from '../components/richtext';
import { GoalAttributeStore } from './mobx/goal-attribute-store';
import styled from 'styled-components';
import {
  trackDiaryAttributeBlur,
  trackDiaryAttributeFocus
} from '../google-analytics/utils';
import { getDisabledReasonMessage } from '../activities/utils';
import { Tippy } from '../components/tippy/tippy';
import { IReactionDisposer, when } from 'mobx';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { VideoSelector } from '../videoselector/video-selector';
import { RichTextErrorBoundary } from '../attributes/richtext/rich-text-error-boundary';
import * as React from 'react';
import { isTouchDevice } from '../utils/is-touch-device';
import { useFetchAutoCompletion } from '../yollanda/hooks/use-fetch-auto-completion';

export interface GoalEditorProps {
  attribute: GoalAttributeStore;
}

interface StyledEditorWrapperProps {
  disabled: boolean;
}

const StyledEditorWrapper = styled.div<StyledEditorWrapperProps>`
  background-color: #ffffff;
  border-radius: 4px;
  ${props =>
    props.disabled
      ? `background-color: #f5f5f5;
         cursor: not-allowed;`
      : ''};
  :focus-within {
    border-color: #4a90e2;
  }
`;
StyledEditorWrapper.displayName = 'StyledEditorWrapper';

function InternalGoalEditor(props: GoalEditorProps): JSX.Element {
  const { attribute } = props;
  const editorWrapper = useRef<HTMLDivElement>(null);
  const disabledReason = attribute.disabledReason;
  const videoSelectorLayer = useLayer('interaction');
  const showEditorRef = useRef<(() => void) | null>(null);
  const fetchAutoCompletion = useFetchAutoCompletion(
    attribute.dataId.athleteId,
    attribute.dataId.groupId
  );

  const onChange = React.useCallback(
    (value: string) => {
      const formattedValue = value === '<p></p>' ? '' : value;
      attribute.onChange(formattedValue);
    },
    [attribute]
  );

  const onFocus = useCallback((): void => {
    attribute.onFocus();
    trackDiaryAttributeFocus(attribute.name);
  }, [attribute]);

  const onBlur = useCallback(() => {
    attribute.onBlur();
    trackDiaryAttributeBlur(attribute.name);
  }, [attribute]);

  useEffect(() => {
    let unsubscribe: IReactionDisposer;

    if (attribute.hasInitialFocus) {
      unsubscribe = when(
        () => !attribute.isDisabled,
        () => {
          showEditorRef.current?.();
          attribute.setInitialFocus(false);
        }
      );
    }

    return () => {
      unsubscribe?.();
    };
  }, [attribute.hasInitialFocus, attribute.isDisabled]);

  return (
    <StyledEditorWrapper disabled={attribute.isDisabled} ref={editorWrapper}>
      <RichTextErrorBoundary>
        <Tippy
          isEnabled={Boolean(disabledReason) && !isTouchDevice()}
          tooltipContent={getDisabledReasonMessage(disabledReason)}
        >
          <RichTextEditor
            data-cy={attribute.name}
            asInput
            placeholder={attribute.placeholder}
            content={attribute.formattedValue}
            disabled={attribute.isDisabled}
            id={`goal-${attribute.trainingDayStore.currentDate}-${attribute.id}`}
            key={`goal-${attribute.trainingDayStore.currentDate}-${attribute.id}-${attribute.isDisabled}-${attribute.dataId.groupId}-${attribute.dataId.athleteId}`}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            showEditorRef={showEditorRef}
            fetchAutocompletion={fetchAutoCompletion}
            plannerEvents={attribute.events}
          />
        </Tippy>
        <LayerPortal
          layerHandle={videoSelectorLayer}
          getContent={layer => <VideoSelector layer={layer} />}
        />
      </RichTextErrorBoundary>
    </StyledEditorWrapper>
  );
}

export const GoalEditor = observer(InternalGoalEditor);
