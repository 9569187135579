import { NavLinkContent } from './nav-link-conent';
import {
  DropdownContext,
  isEvidenceLink,
  isMainNavLink,
  isReportingLink
} from './utils';
import { NavigationLink } from './navigation-link';
import { HeaderLink } from './types';
import {
  DropdownIcon,
  DropdownNavigationList,
  ExternalIcon,
  IconSize,
  MainNavigationDropdownButton
} from '@yarmill/components';
import { useWindowSize } from '../utils/use-window-size';
import {
  autoUpdate,
  ElementProps,
  ExtendedRefs,
  flip,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import { CSSProperties, useCallback, useState } from 'react';

export interface DropdownLinksProps {
  readonly label: JSX.Element;
  readonly icon: JSX.Element;
  readonly links: HeaderLink[];
  readonly id?: string;
}

interface DropdownPortalProps {
  readonly children: JSX.Element;
  readonly refs: ExtendedRefs<Element>;
  readonly floatingStyles: CSSProperties;
  readonly dismiss: ElementProps;
}
function DropdownPortal({
  children,
  refs,
  floatingStyles,
  dismiss
}: DropdownPortalProps): JSX.Element | null {
  const { width = 0 } = useWindowSize();
  const { getFloatingProps } = useInteractions([dismiss]);

  if (width < 768) {
    return children;
  }

  return (
    <FloatingPortal>
      <div
        style={{ ...floatingStyles, zIndex: 4 }}
        {...getFloatingProps()}
        ref={refs.setFloating}
      >
        {children}
      </div>
    </FloatingPortal>
  );
}

export function DropdownLinks(props: DropdownLinksProps): JSX.Element | null {
  const { label, icon, links, id = 'otherNavigationItems' } = props;
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const pathname = window.location.pathname;
  const { refs, floatingStyles, context } = useFloating({
    open: isDropdownOpened,
    onOpenChange: setIsDropdownOpened,
    placement: 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: autoUpdate
  });

  const closeDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dismiss = useDismiss(context, {
    bubbles: true
  });
  const { getReferenceProps } = useInteractions([dismiss]);

  if (links.length === 0) {
    return null;
  }

  if (links.length === 1) {
    const module = links[0];

    return <NavigationLink item={module} />;
  }

  const isActive = links.some(link =>
    pathname.includes(
      isMainNavLink(link)
        ? link.module
        : isReportingLink(link)
        ? link.dashboard.ReportPageCode
        : isEvidenceLink(link)
        ? link.moduleKey
        : '#@#'
    )
  );

  return (
    <li>
      <MainNavigationDropdownButton
        id={id}
        aria-haspopup="true"
        aria-expanded={isDropdownOpened}
        onClick={() => setIsDropdownOpened(o => !o)}
        $isActiveLink={isActive || isDropdownOpened}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <NavLinkContent linkText={label} linkIcon={icon} />
        <DropdownIcon size={IconSize.s16}>
          {isDropdownOpened ? (
            <ExternalIcon name="ChevronUp" />
          ) : (
            <ExternalIcon name="ChevronDown" />
          )}
        </DropdownIcon>
      </MainNavigationDropdownButton>
      <DropdownPortal
        refs={refs}
        floatingStyles={floatingStyles}
        dismiss={dismiss}
      >
        <DropdownContext.Provider value={closeDropdown}>
          <DropdownNavigationList
            aria-labelledby={id}
            isOpened={isDropdownOpened}
          >
            {links.map((module, idx) => (
              <NavigationLink item={module} key={idx} />
            ))}
          </DropdownNavigationList>
        </DropdownContext.Provider>
      </DropdownPortal>
    </li>
  );
}
