import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { DOMSerializer } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import debounce from 'lodash.debounce';
import axios, { CancelTokenSource, isAxiosError } from 'axios';
import { GlobalLogger } from '../../../utils/logger/setGlobalLogger';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    inlineSuggestion: {
      /**
       * fetch inline suggestions
       */
      fetchSuggestion: () => ReturnType;
      /**
       * apply inline suggestion
       */
      applySuggestion: () => ReturnType;
      /**
       * add first character from suggestion
       */
      continueTypeSuggestedText: () => ReturnType;
    };
  }
}

export const inlineSuggestionPluginKey = new PluginKey('inlineSuggestion');
const EMPTY_PARAGRAPH = '<p></p>';

export interface InlineSuggestionOptions {
  /**
   * fetch inline suggestions
   *
   * @param existingText -  existing text in the node
   * @returns {string} - the suggestion to be shown
   */
  fetchAutocompletion: (
    existingText: string,
    cancelToken?: CancelTokenSource
  ) => Promise<string>;
}

export interface InlineSuggestionStorage {
  data: {
    currentSuggestion?: string;
    originalSuggestion?: string;
    position?: number;
  };
  cancelToken?: CancelTokenSource;
}

export const InlineSuggestion = Extension.create<
  InlineSuggestionOptions,
  InlineSuggestionStorage
>({
  name: 'inlineSuggestion',

  addOptions() {
    return {
      fetchAutocompletion: async () => {
        const message =
          'Please add a fetchSuggestion function to fetch suggestions from.';

        return message;
      }
    };
  },

  addStorage() {
    return {
      data: {}
    };
  },

  addCommands() {
    return {
      applySuggestion:
        () =>
        ({ chain, editor }) => {
          const currentSuggestion = this.storage.data.currentSuggestion;

          if (currentSuggestion) {
            const chunkifiedSuggestion = currentSuggestion.split(' ');
            const startWithSpace = chunkifiedSuggestion[0] === '';
            const firstWord = startWithSpace
              ? chunkifiedSuggestion[1]
              : chunkifiedSuggestion[0];
            const contentToInsert = `${
              startWithSpace ? ' ' : ''
            }${firstWord}\u00A0`;
            chain().focus().insertContent(contentToInsert).run();

            chunkifiedSuggestion.splice(0, startWithSpace ? 2 : 1);

            if (chunkifiedSuggestion.length) {
              const prevPosition = this.storage.data.position ?? 0;

              this.storage.data = {
                currentSuggestion: chunkifiedSuggestion.join(' '),
                position: prevPosition + contentToInsert.length
              };

              editor.view.dispatch(
                editor.view.state.tr.setMeta('addToHistory', false)
              );
              return true;
            } else {
              this.storage.data = {};
              return true;
            }
          }

          return false;
        },

      fetchSuggestion:
        () =>
        ({ state, editor }) => {
          this.storage.cancelToken?.cancel();

          const textBefore =
            state?.selection?.$anchor?.nodeBefore?.textContent?.trim();

          const textAfter =
            state?.selection?.$anchor?.nodeAfter?.textContent?.trim();

          if (!textBefore || textAfter) {
            return false;
          }

          const cursorPosition = state.selection.anchor;
          const nodesArray: string[] = [];
          state.doc.nodesBetween(0, cursorPosition, (node, _pos, parent) => {
            if (parent === state.doc) {
              const serializer = DOMSerializer.fromSchema(editor.schema);
              const dom = serializer.serializeNode(node);
              const tempDiv = document.createElement('div');
              tempDiv.appendChild(dom);
              nodesArray.push(tempDiv.innerHTML);
            }
          });

          const existingText = nodesArray.join('');
          const cancelToken = axios.CancelToken.source();
          this.storage.cancelToken = cancelToken;

          if (existingText && existingText.trim() !== EMPTY_PARAGRAPH) {
            this.options
              .fetchAutocompletion(existingText, cancelToken)
              .then(res => {
                if (!editor.isFocused) {
                  return;
                }

                this.storage.data = {
                  currentSuggestion: res,
                  position: cursorPosition
                };

                editor.view.dispatch(
                  editor.view.state.tr.setMeta('addToHistory', false)
                );
              })
              .catch(e => {
                if (isAxiosError(e) && e.code === 'ERR_CANCELED') {
                  return;
                }
                GlobalLogger.error(e);
              });

            return true;
          }

          return false;
        }
    };
  },

  addProseMirrorPlugins() {
    const getStorage = () => this.storage;

    const fetchSuggestion = debounce(
      () => this.editor.commands.fetchSuggestion(),
      10
    );

    const applySuggestion = () => this.editor.commands.applySuggestion();

    const handleNonTabKey = () => (this.storage.data = {});
    const cancelSuggestion = () => this.storage?.cancelToken?.cancel();

    return [
      new Plugin({
        key: inlineSuggestionPluginKey,
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr) {
            const storage = getStorage().data;

            if (
              storage.currentSuggestion &&
              typeof storage.position === 'number'
            ) {
              const { position } = storage;

              const element = document.createElement('span');
              element.innerText = storage.currentSuggestion;
              element.className = 'inline-suggestion';
              const suggestion = Decoration.widget(position, () => element);

              return DecorationSet.create(tr.doc, [suggestion]);
            }

            return DecorationSet.empty;
          }
        },

        props: {
          decorations(state) {
            return this.getState(state);
          },

          handleDOMEvents: {
            blur: () => {
              handleNonTabKey();
            }
          },

          handleKeyDown(_, event: KeyboardEvent) {
            const storage = getStorage().data;
            if (event.key === 'Tab') {
              if (storage.currentSuggestion) {
                event.preventDefault();
                applySuggestion();
                return true;
              } else {
                return false;
              }
            }

            // If user types same characters as suggestion, we do not want to fetch again
            if (
              !event.ctrlKey &&
              !event.metaKey &&
              event.key === storage.currentSuggestion?.[0]
            ) {
              if (!storage.originalSuggestion) {
                storage.originalSuggestion = storage.currentSuggestion;
              }
              storage.currentSuggestion = storage.currentSuggestion.slice(1);
              storage.position = (storage.position ?? 0) + 1;
              return false;
            }

            // If user removes characters and had suggestion before, we can show previous
            if (
              event.key === 'Backspace' &&
              storage.originalSuggestion &&
              storage.currentSuggestion
            ) {
              const diff =
                storage.originalSuggestion.length -
                storage.currentSuggestion.length;

              if (diff !== 0) {
                const prevChar = storage.originalSuggestion[diff - 1];
                storage.currentSuggestion = `${prevChar}${storage.currentSuggestion}`;
                storage.position = (storage.position ?? 1) - 1;
                return false;
              }
            }

            if (
              event.key === 'Backspace' ||
              ((event.key.length === 1 || event.key.length === 2) &&
                event.key !== ' ' &&
                !event.ctrlKey &&
                !event.metaKey)
            ) {
              fetchSuggestion();
            } else if (event.key === ' ') {
              cancelSuggestion();
            }

            handleNonTabKey();
          }
        }
      })
    ];
  }
});
