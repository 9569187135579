import { FormattedMessage } from 'react-intl';

import {
  AddAttachmentButton,
  AttachmentAttributeLabelContainer,
  AttachmentFileInput,
  Attachments,
  Text,
  TextSize,
  TrainingDayAttributeLabel,
  TrainingDayAttributeLayout
} from '@yarmill/components';
import { Tippy } from '../../components/tippy/tippy';

import { useTrainingDayAttributeFileUpload } from '../../training-day/hooks';
import { AttachmentAttributeStore } from '../mobx/attachment-attribute-store';
import { AttachmentItem } from './attachment-item';
import { observer } from 'mobx-react-lite';
import { getDisabledReasonMessage } from '../../activities/utils';

interface AttachmentAttributeProps {
  attribute: AttachmentAttributeStore;
}

function InternalAttachmentAttribute(
  props: AttachmentAttributeProps
): JSX.Element {
  const { attribute } = props;

  const [inputId, inputRef, onChange] =
    useTrainingDayAttributeFileUpload(attribute);
  const files = attribute.attachments;
  const filesUploaded = Boolean(files.length);
  const disabledReason = attribute.disabledReason;

  return (
    <TrainingDayAttributeLayout>
      <AttachmentAttributeLabelContainer
        isHidden={attribute.isHidden}
        filesUploaded={filesUploaded}
      >
        {!attribute.isHidden && (
          <>
            <Tippy tooltipContent={attribute.tooltip}>
              <TrainingDayAttributeLabel htmlFor={inputId}>
                <FormattedMessage id={attribute.name} />
              </TrainingDayAttributeLabel>
            </Tippy>
            <Tippy
              isEnabled={Boolean(disabledReason)}
              tooltipContent={getDisabledReasonMessage(disabledReason)}
            >
              <AddAttachmentButton
                disabled={attribute.isDisabled}
                htmlFor={inputId}
                data-cy={attribute.name}
              >
                <Text size={TextSize.inherit} inheritColor>
                  <FormattedMessage id="trainingDay.attributes.attachment.addFile" />
                </Text>
              </AddAttachmentButton>
            </Tippy>
          </>
        )}
      </AttachmentAttributeLabelContainer>
      <AttachmentFileInput
        ref={inputRef}
        id={inputId}
        type="file"
        name="fileData"
        onChange={onChange}
        multiple
        disabled={attribute.isDisabled}
      />
      <Attachments data-cy="attachments">
        {files.map(file => (
          <AttachmentItem key={file.id} store={file} />
        ))}
      </Attachments>
    </TrainingDayAttributeLayout>
  );
}

export const AttachmentAttribute = observer(InternalAttachmentAttribute);
