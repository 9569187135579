import { ActivityItemsStore } from '../../activities/mobx/activity-items-store';
import { RequestsStore } from '../../api/mobx/requests-store';
import { ConfigStore } from '../../config/config-store';
import { SeasonsStore } from '../../seasons/mobx/seasons-store';
import { HistoryService } from '../../routes/mobx/history-service';
import { AsyncStatus } from '../../api/mobx/request-store';
import { ModulesStore } from '../../modules/mobx/modules-store';
import { computed, observable, runInAction, makeObservable } from 'mobx';
import { CurrentUserStore } from '../../users/mobx/current-user-store';
import { UsersStore } from '../../users/mobx/users-store';
import { TrainingDayAttributesStore } from '../../attributes/mobx/training-day-attributes-store';
import { CopyService } from '../../copy/mobx/copy-service';
import { FileUploadService } from '../../fileupload/mobx/file-upload-service';
import { VideoService } from '../../video-player/mobx/video-service';
import { ImageService } from '../../image-preview/mobx/image-service';
import { WorkoutDetailStore } from '../../workouts/mobx/workout-detail-store';
import { getQueryParam } from '../../utils/get-query-param';
import { FocusedDayService } from '../../diary/mobx/focused-day-service';
import { InactiveUserService } from '../../utils/mobx/inactive-user-service';
import { EvidenceStore } from '../../evidence/mobx/evidence-store';
import { ExportService } from '../../export/mobx/export-service';
import { ReportingStore } from '../../reporting/mobx/reporting-store';
import { GroupsStore } from '../../groups/mobx/groups-store';
import { SettingsService } from '../../settings/mobx/settings-service';
import { GoogleCalendarService } from '../../google-calendar/mobx/google-calendar-service';
import { LayerManagerService } from '../../layer-manager/mobx/layer-manager-service';
import { VideoSelectorService } from '../../videoselector/mobx/video-selector-service';
import { FileDownloadService } from '../../fileupload/mobx/file-download-service';
import { CgmStore } from '../../cgm/mobx/cgm-store';
import { EvidenceSubmitHistoryStore } from '../../evidence/mobx/evidence-submit-history-store';
import { LegacyAnalyticsStore } from '../../analytics/mobx/legacy-analytics-store';
import { handleRedirectAction } from '../../page/utils';
import { AuthStore } from '../../auth/mobx/auth-store';
import { REDIRECT_ACTION_PARAM } from '../utils';
import { MemoryStore } from './memory-store';
import { IntlStore } from '../../intl/mobx/intl-store';
import { NavbarStore } from '../../navbar/mobx/navbar-store';
import { InitialConfigError } from '../../error/initial-config-error';
import { AxiosError } from 'axios';
import { AnnouncementsStore } from '../../announcement/mobx/announcements-store';
import { HeartRateZonesManagerStore } from '../../heart-rate-zones/mobx/heart-rate-zones-manager-store';
import { YollandaService } from '../../yollanda/mobx/yollanda-service';
import { GoogleMapsService } from '../../google-map/mobx/google-maps-service';
import { SidebarStore } from '../../sidebar/mobx/sidebar-store';
import { Logger } from '../../utils/logger/logger';

export class RootStore {
  public logger: Logger;
  public readonly activityItemsStore: ActivityItemsStore;
  public readonly announcementsStore: AnnouncementsStore;
  public readonly configStore: ConfigStore;
  public readonly requestsStore: RequestsStore;
  public readonly seasonsStore: SeasonsStore;
  public readonly modulesStore: ModulesStore;
  public readonly currentUserStore: CurrentUserStore;
  public readonly trainingDayAttributesStore: TrainingDayAttributesStore;
  public readonly usersStore: UsersStore;
  public readonly workoutDetailStore: WorkoutDetailStore;
  public readonly evidenceStore: EvidenceStore;
  public readonly evidenceSubmitHistory: EvidenceSubmitHistoryStore;
  public readonly reportingStore: ReportingStore;
  public readonly groupsStore: GroupsStore;
  public readonly cgmStore: CgmStore;
  public readonly legacyAnalyticsStore: LegacyAnalyticsStore;
  public readonly authStore: AuthStore;
  public readonly memoryStore: MemoryStore;
  public readonly intlStore: IntlStore;
  public readonly navbarStore: NavbarStore;
  public readonly heartRateZonesManagerStore: HeartRateZonesManagerStore;
  public readonly sidebarStore: SidebarStore;

  public readonly historyService: HistoryService;
  public readonly copyService: CopyService;
  public readonly fileUploadService: FileUploadService;
  public readonly videoService: VideoService;
  public readonly imageService: ImageService;
  public readonly focusedDayService: FocusedDayService;
  public readonly inactiveUserService: InactiveUserService;
  public readonly exportService: ExportService;
  public readonly settingsService: SettingsService;
  public readonly googleCalendarService: GoogleCalendarService;
  public readonly layerManagerService: LayerManagerService;
  public readonly videoSelectorService: VideoSelectorService;
  public readonly fileDownloadService: FileDownloadService;
  public readonly yollandaService: YollandaService;
  public readonly googleMapsService: GoogleMapsService;

  @observable
  public status: AsyncStatus = AsyncStatus.idle;

  constructor(logger: Logger) {
    makeObservable(this);
    this.logger = logger;
    this.logger.setRootStore(this);

    this.authStore = new AuthStore(this);

    this.historyService = new HistoryService();
    this.copyService = new CopyService(this);
    this.fileUploadService = new FileUploadService(this);
    this.videoService = new VideoService(this);
    this.imageService = new ImageService();
    this.focusedDayService = new FocusedDayService();
    this.exportService = new ExportService(this);
    this.settingsService = new SettingsService(this);
    this.googleCalendarService = new GoogleCalendarService(this);
    this.layerManagerService = new LayerManagerService();
    this.videoSelectorService = new VideoSelectorService();
    this.fileDownloadService = new FileDownloadService(this);
    this.yollandaService = new YollandaService(this);
    this.googleMapsService = new GoogleMapsService(this);

    this.navbarStore = new NavbarStore(this);
    this.requestsStore = new RequestsStore();
    this.activityItemsStore = new ActivityItemsStore(this);
    this.configStore = new ConfigStore(this);
    this.currentUserStore = new CurrentUserStore(this);
    this.modulesStore = new ModulesStore(this);
    this.seasonsStore = new SeasonsStore(this);
    this.trainingDayAttributesStore = new TrainingDayAttributesStore(this);
    this.usersStore = new UsersStore(this);
    this.groupsStore = new GroupsStore(this);
    this.workoutDetailStore = new WorkoutDetailStore(this);
    this.evidenceStore = new EvidenceStore(this);
    this.evidenceSubmitHistory = new EvidenceSubmitHistoryStore();
    this.reportingStore = new ReportingStore(this);
    this.cgmStore = new CgmStore(this);
    this.legacyAnalyticsStore = new LegacyAnalyticsStore(this);
    this.announcementsStore = new AnnouncementsStore(this);
    this.heartRateZonesManagerStore = new HeartRateZonesManagerStore(this);

    this.sidebarStore = new SidebarStore();
    this.memoryStore = new MemoryStore();
    this.intlStore = new IntlStore(this);

    this.inactiveUserService = new InactiveUserService(this);

    const tokenInUrl = getQueryParam('token');
    if (tokenInUrl) {
      this.loadRegistrationConfig();
    } else {
      if (!this.authStore.isLoggedIn) {
        this.loadLoginScreenConfiguration();
      } else {
        this.loadInitialConfiguration();
      }
    }
  }

  @computed
  public get isReady(): boolean {
    return this.status === AsyncStatus.resolved;
  }

  private async loadInitialConfiguration(): Promise<void> {
    if (!this.authStore.isLoggedIn) {
      return;
    }

    try {
      runInAction(() => {
        this.status = AsyncStatus.pending;
      });

      await runInAction(async () => {
        await Promise.all([
          this.intlStore.load(),
          this.activityItemsStore.loadActivityItems(),
          this.configStore.loadConfig(),
          this.currentUserStore.loadCurrentUser(),
          this.seasonsStore.loadSeasons(),
          this.trainingDayAttributesStore.loadTrainingDayAttributes(),
          this.usersStore.loadUsers(),
          this.groupsStore.loadGroups(),
          this.legacyAnalyticsStore.loadDashboards()
        ]);
      });

      runInAction(() => {
        handleRedirectAction(
          this,
          this.historyService.searchParams.get(REDIRECT_ACTION_PARAM)
        );
        this.status = AsyncStatus.resolved;
      });

      await this.authStore.regenerateAuthToken();
    } catch (e: unknown) {
      const isTermsError =
        e instanceof InitialConfigError &&
        e.originalError instanceof AxiosError &&
        e.originalError.response?.status === 401;

      if (!isTermsError) {
        this.logger.error(
          typeof e === 'object' && e && 'message' in e ? e.message : e
        );
      }
      runInAction(() => {
        this.status = AsyncStatus.rejected;
      });
    }
  }

  private async loadRegistrationConfig(): Promise<void> {
    try {
      this.status = AsyncStatus.pending;

      await Promise.all([
        this.intlStore.load(),
        this.configStore.loadConfig(),
        this.currentUserStore.loadCurrentUserWithoutPermissions()
      ]);

      this.status = AsyncStatus.resolved;
    } catch (e: unknown) {
      this.logger.error(
        typeof e === 'object' && e && 'message' in e ? e.message : e
      );
      this.status = AsyncStatus.resolved;
    }
  }
  private async loadLoginScreenConfiguration(): Promise<void> {
    try {
      this.status = AsyncStatus.pending;

      await Promise.all([this.intlStore.load()]);

      this.status = AsyncStatus.resolved;
    } catch (e: unknown) {
      this.logger.error(
        typeof e === 'object' && e && 'message' in e ? e.message : e
      );
      this.status = AsyncStatus.resolved;
    }
  }
}
