import { Layer } from '../../layer-manager/mobx/layer';
import styled, { css } from 'styled-components';
import { EvidenceAttribute, EvidenceObjectHistoryItem } from '../types';
import { Avatar } from '../../profile/avatar';
import { useUsersStore } from '../../users/hooks';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { normalizeString } from '../../command-palette/utils';
import {
  Button,
  ButtonAppearance,
  KeyboardShortcut,
  Text,
  TextSize
} from '@yarmill/components';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { renderAttributeValue } from '../evidence-attribute';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { EvidenceAttributeStore } from '../mobx/evidence-attribute-store';
import { RichTextEditor } from '../../components/richtext';

interface PrefillLayerProps {
  dataStore: EvidenceObjectDataStore;
  layer: Layer;
  history: EvidenceObjectHistoryItem[];
  attributes?: EvidenceAttribute[];
}

const StyledPrefillLayer = styled.div`
  width: 640px;
  height: 400px;
  max-width: 90vw;
  background-image: linear-gradient(
    136.61deg,
    rgb(49, 49, 54) 13.72%,
    rgb(43, 43, 50) 74.3%
  );
  display: grid;
  grid-template-areas:
    'search search'
    'list detail'
    'bar bar';
  grid-template-columns: 3fr 4fr;
  grid-template-rows: auto 1fr 44px;
  color: rgb(214, 214, 214);
`;

const InputWrapper = styled.div`
  grid-area: search;
`;

const Input = styled.input`
  padding: 20px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(214, 214, 214);
  font-size: 18px;
  border-bottom: 1px solid rgb(60, 60, 64);
  font-family: Ubuntu;
`;

const ListWrapper = styled.div`
  height: 100%;
  grid-area: list;
  border-right: 1px solid rgb(60, 60, 64);
  overflow-y: auto;
`;

const ListItem = styled.div<{ selected: boolean }>`
  height: 46px;
  display: grid;
  grid-template-columns: 24px auto auto;
  grid-column-gap: 10px;
  align-items: center;

  color: rgb(214, 214, 214);
  border-left: 2px solid transparent;
  padding: 0 20px;
  cursor: pointer;

  :hover {
    background-color: rgb(55, 55, 60);
    border-left-color: rgb(94, 106, 210);
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgb(55, 55, 60);
      border-left-color: rgb(94, 106, 210);
    `}
`;

const List = styled.div`
  display: grid;
  align-content: flex-start;
`;

const AvatarWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const DetailWrapper = styled.div`
  grid-area: detail;
  padding: 12px 20px;
  overflow-y: auto;
  position: relative;
`;

const ValuesTable = styled.table`
  text-align: left;
  th,
  td {
    padding: 5px 10px;
    vertical-align: top;
  }
`;

const PreviewValue = styled.small`
  justify-self: flex-end;
`;

const CommandBar = styled.div`
  grid-area: bar;
  border-top: 1px solid rgb(60, 60, 64);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
`;

export function PrefillLayer(props: PrefillLayerProps): JSX.Element {
  const { history, attributes, layer, dataStore } = props;
  const usersStore = useUsersStore();
  const intl = useIntl();
  const [filter, setFilter] = useState('');
  const [selectedHistoryItem, setSelectedHistoryItem] = useState<number>(0);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedHistoryItem(0);
    setFilter(e.target?.value);
  };

  const filteredHistory = useMemo(
    () =>
      history
        .slice()
        .reverse()
        .filter(item => {
          const user = usersStore.getUserById(item.userId);
          const normalizedFilter = normalizeString(filter).toLocaleLowerCase();
          return (
            normalizeString(user?.displayName || '')
              .toLocaleLowerCase()
              .includes(normalizedFilter) ||
            item.data.find(({ AttributeId, AttributeValue }) => {
              const attribute = attributes?.find(
                attr => attr.AttributeId === AttributeId
              );
              if (!attribute) {
                return false;
              }

              return normalizeString(
                renderAttributeValue(attribute, String(AttributeValue), intl)
              )
                .toLocaleLowerCase()
                .includes(normalizedFilter);
            })
          );
        }),
    [attributes, filter, history, intl, usersStore]
  );

  const filteredHistoryCount = filteredHistory.length;

  const prefill = useCallback(() => {
    layer.close();
    if (selectedHistoryItem === null) {
      return;
    }
    const item = filteredHistory[selectedHistoryItem];
    item?.data.forEach(({ AttributeId, AttributeValue }) => {
      if (AttributeValue) {
        const attribute = dataStore.getAttribute(AttributeId);
        const value = AttributeValue;
        if (
          attribute instanceof EvidenceAttachmentStore &&
          typeof value === 'object' &&
          value
        ) {
          attribute.prefill(value);
        } else if (
          attribute instanceof EvidenceAttributeStore &&
          typeof value !== 'object'
        ) {
          attribute.prefill(value);
        }
      }
    });
  }, [dataStore, filteredHistory, layer, selectedHistoryItem]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setSelectedHistoryItem(s => (!s ? filteredHistoryCount - 1 : s - 1));
      } else if (e.key === 'ArrowDown') {
        setSelectedHistoryItem(s =>
          s === null ? 0 : (s + 1) % filteredHistoryCount
        );
      } else if (e.key === 'Enter') {
        e.preventDefault();
        prefill();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        layer.close();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [layer, filteredHistoryCount, prefill]);

  return (
    <StyledPrefillLayer>
      <InputWrapper>
        <Input
          type="text"
          autoFocus
          placeholder={intl.formatMessage({ id: 'evidence.history.search' })}
          value={filter}
          onChange={handleInputChange}
        />
      </InputWrapper>
      <ListWrapper>
        <List>
          {filteredHistory.map((item, index) => {
            const user = usersStore.getUserById(item.userId);

            return (
              <ListItem
                onClick={() => setSelectedHistoryItem(index)}
                selected={selectedHistoryItem === index}
              >
                <AvatarWrapper>
                  <Avatar id={user?.avatar || null} />
                </AvatarWrapper>
                <Text size={TextSize.s14} inheritColor>
                  {user?.displayName}
                </Text>

                <PreviewValue>
                  {String(
                    item.data.find(i => i.AttributeValue)?.AttributeValue
                  )}
                </PreviewValue>
              </ListItem>
            );
          })}
        </List>
      </ListWrapper>
      <DetailWrapper>
        {selectedHistoryItem !== null && (
          <ValuesTable>
            {filteredHistory[selectedHistoryItem]?.data.map(
              ({ AttributeId, AttributeValue }) => {
                const attribute = attributes?.find(
                  attr => attr.AttributeId === AttributeId
                );

                return (
                  <tr key={AttributeId}>
                    <th>
                      <Text size={TextSize.s14} inheritColor bold>
                        {attribute ? (
                          <FormattedMessage id={attribute.AttributeName} />
                        ) : (
                          AttributeId
                        )}
                      </Text>
                    </th>
                    <td>
                      <Text size={TextSize.s14} inheritColor>
                        {attribute ? (
                          attribute.AttributeTypeKey === 'rich-text' ? (
                            <RichTextEditor
                              content={AttributeValue as string}
                              readOnly
                            />
                          ) : (
                            renderAttributeValue(
                              attribute,
                              AttributeValue ?? '',
                              intl
                            )
                          )
                        ) : (
                          String(AttributeValue)
                        )}
                      </Text>
                    </td>
                  </tr>
                );
              }
            )}
          </ValuesTable>
        )}
      </DetailWrapper>
      <CommandBar>
        {selectedHistoryItem !== null && (
          <Button
            appearance={ButtonAppearance.Link}
            type="button"
            inverted
            small
            onClick={prefill}
          >
            <FormattedMessage id="evidence.history.prefill" />
            &nbsp; &nbsp;
            <KeyboardShortcut>Enter</KeyboardShortcut>
          </Button>
        )}
      </CommandBar>
    </StyledPrefillLayer>
  );
}
