import {
  FilterOptionOption,
  UsersSelect,
  UsersSelectOption
} from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Avatar } from '../profile/avatar';
import { getNormalizedString } from '../utils/get-normalized-string';
import { useMemo } from 'react';
import { useUsersStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import { RoleTag } from '../components/role-tag';
import { UserId } from '../users/types';

export interface UserSelectProps {
  autoFocus?: boolean;
  excludedUsers?: UserId[];
  includedUsers?: UserId[];
  defaultUsers?: UserId[];
  onChange(userIds: { label: string; value: UserId }[]): void;
  handleSubmit?(): void;
}

export const UserSelect = observer(function UserSelect(
  props: UserSelectProps
): JSX.Element {
  const {
    handleSubmit,
    onChange,
    autoFocus,
    excludedUsers,
    includedUsers,
    defaultUsers
  } = props;
  const usersStore = useUsersStore();

  const intl = useIntl();

  const filterOption = (
    candidate: FilterOptionOption<UsersSelectOption>,
    inputString: string
  ): boolean => {
    const userEmail = candidate.data.email;
    const userLabel = candidate.data.label;

    if (inputString) {
      const normalizedMail = getNormalizedString(userEmail);
      const normalizedLabel = getNormalizedString(userLabel);

      const normalizedInput = getNormalizedString(inputString);

      return (
        normalizedMail.toLowerCase().includes(normalizedInput.toLowerCase()) ||
        normalizedLabel.toLowerCase().includes(normalizedInput.toLowerCase())
      );
    }

    return true;
  };

  const options = useMemo(
    () =>
      usersStore.activeUsers
        .filter(user => !includedUsers || includedUsers.includes(user.id))
        .filter(
          user => !excludedUsers || excludedUsers?.indexOf(user.id) === -1
        )
        .map<UsersSelectOption>(user => ({
          value: user.id,
          label: user.displayName,
          email: user.internalUser.Email,
          role: <RoleTag role={user.internalUser.Role} />,
          avatar: <Avatar id={user.avatar} alt={user.displayName} />
        })),
    [usersStore, excludedUsers]
  );

  const defaultValue = defaultUsers
    ? options.filter(o => defaultUsers.includes(o.value))
    : undefined;

  return (
    <UsersSelect
      autoFocus={autoFocus}
      filterOption={filterOption}
      options={options}
      handleSubmit={handleSubmit}
      onChange={onChange}
      placeholder={intl.formatMessage({
        id: 'settings.groups.createNew.invites.select.placeholder'
      })}
      noOptionsMessage={intl.formatMessage({
        id: 'settings.groups.createNew.invites.select.noOptions'
      })}
      defaultValue={defaultValue}
    />
  );
});
