import { FormField } from './form-fields/form-field';
import { observer } from 'mobx-react-lite';
import { EvidenceAttributeStore } from './mobx/evidence-attribute-store';
import { IntlShape, useIntl } from 'react-intl';
import { Text, TextSize, TextTag, WhiteSpace } from '@yarmill/components';
import { Tippy } from '../components/tippy/tippy';
import {
  EvidenceAttribute as iEvidenceAttribute,
  EvidenceAttributeValue
} from './types';
import { RichTextEditor } from '../components/richtext';
import { File } from '../fileupload/types';
import { EvidenceAttachmentStore } from './mobx/evidence-attachment-store';
import { EvidenceFileAttribute } from './attributes/evidence-file-attribute';

export interface EvidenceAttributeProps {
  attributeStore?: EvidenceAttributeStore | EvidenceAttachmentStore;
  showEditor: boolean;
  index: number;
}

export function renderAttributeValue(
  definition: iEvidenceAttribute,
  value: EvidenceAttributeValue,
  intl: IntlShape
): string {
  const translateValue = definition.TranslateValue;

  if (definition.AttributeTypeKey === 'dropdown') {
    const option = definition.SourceData.find(
      o => String(o.Key) === String(value)
    );

    if (option) {
      return intl.formatMessage({ id: option.Value });
    }
  }

  if (!value) {
    return '-';
  }

  if (definition.AttributeTypeKey === 'attachment') {
    const filesArray = Array.isArray(value)
      ? value
      : [value as unknown as File];
    return filesArray.map(f => f.FileName).join(', ');
  }

  const stringValue = String(value);

  if (definition.AttributeTypeKey === 'date') {
    return intl.formatDate(stringValue, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  return translateValue ? intl.formatMessage({ id: stringValue }) : stringValue;
}

function renderValue(
  attributeStore: EvidenceAttributeStore | EvidenceAttachmentStore,
  intl: IntlShape
) {
  const definition = attributeStore.definition;
  switch (definition.AttributeTypeKey) {
    case 'rich-text':
      return (
        <RichTextEditor
          content={(attributeStore as EvidenceAttributeStore).value}
          readOnly
        />
      );
    case 'attachment':
      return (
        <EvidenceFileAttribute
          attribute={attributeStore as EvidenceAttachmentStore}
        />
      );
    default:
      return renderAttributeValue(
        attributeStore.definition,
        (attributeStore as EvidenceAttributeStore).value,
        intl
      );
  }
}

export const EvidenceAttribute = observer(function EvidenceAttribute(
  props: EvidenceAttributeProps
): JSX.Element {
  const { showEditor, attributeStore, index } = props;

  const intl = useIntl();

  if (!attributeStore) {
    return <>-</>;
  }

  if (
    showEditor &&
    attributeStore instanceof EvidenceAttributeStore &&
    attributeStore.definition.IsEditable
  ) {
    return (
      <FormField attributeStore={attributeStore} autoFocus={index === 0} />
    );
  }

  return (
    <Tippy
      tooltipContent={attributeStore.definition.Tooltip}
      isEnabled={Boolean(attributeStore.definition.Tooltip)}
    >
      <Text
        whiteSpace={WhiteSpace.preWrap}
        tag={TextTag.span}
        size={TextSize.s14}
      >
        {attributeStore.hasValue ||
        attributeStore.attributeType === 'attachment' ? (
          <>
            {attributeStore.definition.PrefixKey}
            {renderValue(attributeStore, intl)}
            {attributeStore.definition.SuffixKey}
          </>
        ) : (
          renderAttributeValue(
            attributeStore.definition,
            attributeStore instanceof EvidenceAttributeStore
              ? attributeStore.value
              : [],
            intl
          )
        )}
      </Text>
    </Tippy>
  );
});
