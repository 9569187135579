import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when
} from 'mobx';

import { RootStore } from '../../app/mobx/root-store';
import { User, UserAccountState, userCookies, UserId } from '../types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { getUsers } from '../api/get-users';
import { UserStore } from './user-store';
import { getDeactivatedUsers } from '../api/get-deactivated-users';
import { reactivateUser } from '../api/reactivate-user';
import moment from 'moment';
import { DATE_FORMAT } from '../../diary/utils';
import { getUsersInGroups } from '../api/get-users-in-groups';
import { InitialConfigError } from '../../error/initial-config-error';
import { impersonateUser } from '../api/impersonate-user';
import { sortUserStores } from '../utils/sort-user-stores';

export class UsersStore {
  private readonly rootStore: RootStore;

  private request: RequestStore<User[]> | null = null;

  @observable
  private _users = new Map<UserId, UserStore>();

  @observable
  private _deactivatedUsers = new Map<UserId, UserStore>();

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private _deactivatedUsersStatus: AsyncStatus = AsyncStatus.idle;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public async loadUsers(): Promise<void> {
    this._status = AsyncStatus.pending;
    this.request = this.rootStore.requestsStore.createRequest(() => getUsers());
    const usersInGroupsRequest = this.rootStore.requestsStore.createRequest(
      () => getUsersInGroups()
    );

    const response = await this.request.getResponse();
    if (response) {
      runInAction(() => {
        this.setUsers(response);
        this._status = AsyncStatus.resolved;
      });
      const usersInGroups = await usersInGroupsRequest.getResponse();

      if (usersInGroups) {
        when(
          () => this.rootStore.groupsStore.status === AsyncStatus.resolved,
          () => {
            runInAction(() => {
              usersInGroups.forEach(item => {
                const user = this.getUserById(item.UserId);
                item.UserGroupIds.forEach(groupId => {
                  const group =
                    this.rootStore.groupsStore.getGroupById(groupId);
                  if (group) {
                    user?.addGroup(group);
                  }
                });
              });
            });
          }
        );
      } else {
        throw new InitialConfigError(
          'Unable to load users in groups',
          usersInGroupsRequest.error
        );
      }
    } else {
      this._status = AsyncStatus.rejected;
      throw new InitialConfigError('Unable to load users', this.request.error);
    }
  }

  public async loadDeactivatedUsers(): Promise<void> {
    this._deactivatedUsersStatus = AsyncStatus.pending;
    const transaction =
      this.rootStore.navbarStore.createTransaction('loadingData');
    const request = this.rootStore.requestsStore.createRequest(() =>
      getDeactivatedUsers()
    );

    const response = await request.getResponse();
    runInAction(() => {
      if (response) {
        response.forEach(user => {
          this._deactivatedUsers.set(
            user.UserId,
            new UserStore(this.rootStore, user)
          );
        });
      }
      this._deactivatedUsersStatus = AsyncStatus.resolved;
      transaction.finished();
    });
  }

  @action
  public setUsers(users: User[]): void {
    users.forEach(item => {
      this._users.set(item.UserId, new UserStore(this.rootStore, item));
    });
  }

  @action
  public removeUser(userId: UserId): void {
    const user = this._users.get(userId);
    this._users.delete(userId);
    if (user) {
      user.internalUser.AccountStateId = UserAccountState.CANCELED;
      user.internalUser.MemberUntil = moment().format(DATE_FORMAT);
      this._deactivatedUsers.set(userId, user);
    }
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  public get deactivatedUsersStatus(): AsyncStatus {
    return this._deactivatedUsersStatus;
  }
  public getUserById(id?: UserId | null): UserStore | undefined {
    if (!id) {
      return undefined;
    }
    return this._users.get(id);
  }

  public getDeactivatedUserById(id?: UserId | null): UserStore | undefined {
    if (!id) {
      return undefined;
    }
    return this._deactivatedUsers.get(id);
  }

  @computed
  public get activeUsers(): UserStore[] {
    return Array.from(this._users.values())
      .filter(user => user.isActive)
      .sort((a, b) => a.id - b.id)
      .sort(sortUserStores);
  }

  @computed
  public get allUsers(): UserStore[] {
    return Array.from(this._users.values())
      .concat(Array.from(this._deactivatedUsers.values()))
      .sort((a, b) => a.id - b.id)
      .sort(sortUserStores);
  }

  public async reactivateUser(userId: UserId): Promise<boolean> {
    const user = this._deactivatedUsers.get(userId);

    if (!user) {
      return false;
    }

    const transaction =
      this.rootStore.navbarStore.createTransaction('loadingData');

    const request = this.rootStore.requestsStore.createRequest(() =>
      reactivateUser(userId)
    );

    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        user.update(response);
        this._users.set(userId, user);
        this._deactivatedUsers.delete(userId);
        transaction.finished();
        return true;
      } else {
        transaction.error();
        return false;
      }
    });
  }

  public async impersonateUser(userId: UserId): Promise<void> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      impersonateUser(userId)
    );

    const response = await request.getResponse();

    if (response) {
      window.sessionStorage.setItem(
        userCookies.IMPERSONATED_USER,
        response.Token
      );
    } else {
      throw new Error('invalid user token');
    }
  }

  @computed
  public get count(): number {
    return this.activeUsers.length;
  }

  @computed
  public get paid(): number {
    return this.activeUsers.length;
  }

  @computed
  public get athletes(): UserStore[] {
    return this.activeUsers.filter(user => user.isAthlete);
  }

  @computed
  public get coaches(): UserStore[] {
    return this.activeUsers.filter(user => user.isCoach);
  }
}
