import { updateSetting } from '../api/update-setting';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { RootStore } from '../../app/mobx/root-store';
import { ReportPageCode } from '../../reporting/types';
import { PersonalizedSettingsConfiguration } from '../types';
import { SeasonId } from '../../seasons/types';
import {
  ANNOUNCEMENT_PERSIST_PREFIX,
  AnnouncementCode
} from '../../announcement/types';

export type SettingsKey =
  | 'mask.selectedMask'
  | `season.startDate.${SeasonId}`
  | 'app.language'
  | 'heartRateZones.usePolarZones'
  | `reporting.${ReportPageCode}.subscribe`
  | `diary.attributesPrintDialogSettings.${'plan' | 'reality'}`
  | `${typeof ANNOUNCEMENT_PERSIST_PREFIX}${AnnouncementCode}`;
export interface EntityIdentifier {
  userId?: UserId;
  groupId?: UserGroupId;
}

export class SettingsService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public async saveSettings<K extends SettingsKey>(
    key: K,
    value: PersonalizedSettingsConfiguration[K],
    entityIdentifier: EntityIdentifier,
    isGlobal?: boolean
  ): Promise<boolean | undefined> {
    if (entityIdentifier.userId) {
      const user = this.rootStore.usersStore.getUserById(
        entityIdentifier.userId
      );
      user?.updateSetting(key, value);
      if (entityIdentifier.userId === this.rootStore.currentUserStore.id) {
        this.rootStore.currentUserStore.updateSetting(key, value);
      }
    } else if (entityIdentifier.groupId) {
      const group = this.rootStore.groupsStore.getGroupById(
        entityIdentifier.groupId
      );
      group?.updateSetting(key, value);
    }

    const request = this.rootStore.requestsStore.createRequest(() =>
      updateSetting({
        SettingsKey: key,
        Value: value,
        UserId: entityIdentifier.userId,
        UserGroupId: entityIdentifier.userId
          ? undefined
          : entityIdentifier.groupId,

        IsGlobal: isGlobal
      })
    );

    return await request.getResponse();
  }
}
