import styled from 'styled-components';
import { Editor } from '@tiptap/react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Button } from './button';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import { Tippy } from '../../tippy/tippy';
import { useIntl } from 'react-intl';
import { ExternalIcon } from '@yarmill/components';

const OtherButtonsContainer = styled.div<{
  readonly isWiderThanContainer?: boolean;
}>`
  background: #f8f8f8;
  display: flex;
  border-color: #e1e4e6;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  ${({ isWiderThanContainer }) =>
    isWiderThanContainer && `border-bottom-left-radius: 4px;`};
`;

interface OtherButtonProps {
  readonly buttons: JSX.Element[];
  readonly editor: Editor;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
  readonly isWide: boolean;
}

interface OtherButtonsMenuProps {
  readonly widerThanContainer: boolean;
  readonly elements: JSX.Element[];
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
}

export const OtherButtonsMenuContext = createContext<{
  readonly closeMenu: () => void;
}>({ closeMenu: () => {} });
function OtherButtonsMenu({
  elements,
  widerThanContainer,
  setKeepControlsVisible
}: OtherButtonsMenuProps) {
  useEffect(() => {
    return () => {
      setKeepControlsVisible(v => Math.max(0, v - 1));
    };
  }, [setKeepControlsVisible]);
  return (
    <OtherButtonsContainer
      isWiderThanContainer={widerThanContainer}
      className="richtext-others-picker"
    >
      {elements}
    </OtherButtonsContainer>
  );
}

export function OtherButton({
  buttons,
  editor,
  setKeepControlsVisible,
  isWide
}: OtherButtonProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);
  const intl = useIntl();
  const label = 'richtext.toolbar.other';

  const { refs, floatingStyles, context } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    placement: 'top-end',
    middleware: [flip(), size(), offset({ mainAxis: 3 })],
    whileElementsMounted: autoUpdate
  });

  function closePicker() {
    setIsOpened(false);
    editor.chain().focus().run();
  }

  const dismiss = useDismiss(context, {
    bubbles: true
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const otherButtonsMenuContextValue = useMemo(
    () => ({
      closeMenu: () => setIsOpened(false)
    }),
    [setIsOpened]
  );

  return (
    <>
      <Tippy tooltipContent={label} noWrapper>
        <Button
          type="button"
          ref={refs.setReference}
          aria-label={intl.formatMessage({ id: label })}
          onMouseDown={() => {
            if (isOpened) {
              closePicker();
            } else {
              setKeepControlsVisible(v => v + 1);
              setIsOpened(true);
            }
          }}
          {...getReferenceProps()}
        >
          <ExternalIcon name="DotsVertical" />
        </Button>
      </Tippy>
      {isOpened && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 3 }}
            {...getFloatingProps()}
          >
            <OtherButtonsMenuContext.Provider
              value={otherButtonsMenuContextValue}
            >
              <OtherButtonsMenu
                widerThanContainer={isWide}
                elements={buttons.map(b => b)}
                setKeepControlsVisible={setKeepControlsVisible}
              />
            </OtherButtonsMenuContext.Provider>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
