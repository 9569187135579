import { useFileUpload } from '../../fileupload/hooks/use-file-upload';
import { useCallback } from 'react';
import { FileUploadStore } from '../../fileupload/mobx/file-upload-store';
import { Id } from 'react-toastify';
import {
  toastErrorFileUpload,
  toastSuccessFileUpload
} from '../../fileupload/file-upload';
import { File } from '../../fileupload/types';

export function useFilesOverviewFileUpload(
  addAttachment: (file: File) => void,
  addPendingAttachment: (store: FileUploadStore) => void,
  removePendingAttachment: (store: FileUploadStore) => void
) {
  const onSuccess = useCallback(
    async (fileUpload: FileUploadStore, toastId: Id) => {
      const file = fileUpload.uploadedFile;
      if (file) {
        addAttachment(fileUpload.uploadedFile);

        toastSuccessFileUpload(toastId);
      } else {
        toastErrorFileUpload(toastId);
      }
      removePendingAttachment(fileUpload);
    },
    [addAttachment, removePendingAttachment]
  );

  const onStart = useCallback(
    (fileUpload: FileUploadStore) => {
      addPendingAttachment(fileUpload);
    },
    [addPendingAttachment]
  );

  const onError = useCallback(
    (fileUpload: FileUploadStore) => {
      removePendingAttachment(fileUpload);
    },
    [removePendingAttachment]
  );

  return useFileUpload(onSuccess, onError, onStart);
}
