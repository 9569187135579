import {
  isBrowser,
  StyledTd,
  Text,
  TextSize,
  WhiteSpace
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { formatNumber } from '../utils/format-number';
import { formatValueByUnit } from '../activities/utils';
import {
  CsvTableAlignment,
  CsvTableDataItem,
  CsvTableHeader,
  CsvTableRowData,
  CsvTableUnit,
  CsvTableVerticalAlignment
} from './types';
import { ReportData, ReportingDataBusinessFormat } from '../reporting/types';
import { formatValueByBusinessFormat, mapColor } from '../reporting/utils';
import { Units } from '../units/types';
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo
} from 'react';
import { useIntl } from 'react-intl';
import { Link } from '../components/link';
import { Tippy } from '../components/tippy/tippy';
import { Report } from '../reporting/report';
import { useGetCellReportDefinition } from './hooks/use-get-cell-report-definition';
import { CsvTableCellContext } from './csv-table-cell-context';
import { calculateColumnPrintWidth } from './utils/calculate-column-print-width';
import { ReportContext } from '../reporting/context/report-context';

export interface CsvTableCellProps {
  readonly data: CsvTableDataItem | undefined;
  readonly rowData: CsvTableRowData | undefined;
  readonly formattedRowData: CsvTableRowData | undefined;
  readonly unit?: CsvTableUnit | null;
  readonly businessFormat?: ReportingDataBusinessFormat | null;
  readonly format?: string | null;
  readonly alignment?: CsvTableAlignment | null;
  readonly translateValue?: boolean;
  readonly link?: (item: CsvTableRowData) => string;
  readonly onLinkClick?: (item: CsvTableRowData) => void;
  readonly borderRight?: boolean;
  readonly borderLeft?: boolean;
  readonly rowSpan?: number;
  readonly colSpan?: number;
  readonly noWrap?: boolean;
  readonly getTooltipContent?: (item: CsvTableRowData) => ReactNode | undefined;
  readonly getColor?: (item: CsvTableRowData) => string | undefined;
  readonly color?: string | null;
  readonly verticalAlignment?: CsvTableVerticalAlignment | null;
  readonly name: string;
  readonly headerConfig?: CsvTableHeader;
}

interface StyledCsvTableCellProps {
  borderRight?: boolean;
  borderLeft?: boolean;
  color?: string;
  verticalAlignment?: CsvTableVerticalAlignment | null;
}

const StyledCsvTableCell = styled(StyledTd)<StyledCsvTableCellProps>`
  padding: 4px 8px;
  font-family: 'Roboto Mono', 'Apple Color Emoji', 'Noto Color Emoji', serif;
  text-align: right;
  ${({ borderRight }) => borderRight && 'border-right: 1px solid #e0e0e0;'}
  ${({ borderLeft }) => borderLeft && 'border-left: 1px solid #e0e0e0;'}
  border-left-color: #eaeaea;
  border-right-color: #eaeaea;
  font-size: 12px;
  ${({ color }) => color && `color: ${color};`};
  vertical-align: ${({ verticalAlignment }) =>
    verticalAlignment === 'center' ? 'middle' : verticalAlignment ?? 'middle'};

  @media print {
    page-break-inside: avoid;
  }
`;

const IconWrapper = styled(Text)<{ readonly align?: CsvTableAlignment }>`
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
  justify-content: ${({ align }) =>
    align === 'right'
      ? 'flex-end'
      : align === 'center'
      ? 'center'
      : 'flex-start'};
`;

function formatValue(
  value: string | number | undefined | null,
  unit?: CsvTableUnit | null,
  businessFormat?: ReportingDataBusinessFormat | null,
  format?: string | null
): ReactNode {
  if (businessFormat === 'report') {
    return '';
  }

  if (businessFormat) {
    return formatValueByBusinessFormat(value, businessFormat, format || null, {
      monoSpace: true
    });
  }

  if (unit && unit !== 'short-text') {
    switch (unit) {
      case 'report':
        return <></>;
      case 'number':
      case 'decimal-number':
        return formatNumber(value);
      default:
        return formatValueByUnit(value, unit);
    }
  }

  return value;
}

function shouldWrap(
  unit?: CsvTableUnit | null,
  businessFormat?: ReportingDataBusinessFormat | null
): boolean {
  if (
    unit === Units.hod ||
    unit === Units.min ||
    unit === Units.longTime ||
    unit === 'number' ||
    unit === 'decimal-number'
  ) {
    return false;
  } else if (businessFormat === 'duration' || businessFormat === 'percent') {
    return false;
  }
  return true;
}

export const CsvTableCell = observer(function CsvTableCell(
  props: CsvTableCellProps
): JSX.Element {
  const {
    data,
    unit,
    businessFormat,
    format,
    alignment,
    translateValue,
    link,
    rowData,
    onLinkClick,
    borderLeft,
    borderRight,
    rowSpan,
    noWrap,
    colSpan,
    getTooltipContent,
    getColor,
    verticalAlignment,
    name,
    headerConfig,
    formattedRowData
  } = props;
  const intl = useIntl();
  const reportContext = useContext(ReportContext);
  const getCellReportDefinition = useGetCellReportDefinition(name);
  const translatedValue =
    translateValue && data && typeof data === 'string' && intl.messages[data]
      ? intl.formatMessage(
          { id: String(data) },
          formattedRowData as Record<string, string>
        )
      : data;
  const reportDefinition =
    businessFormat === 'report' ? getCellReportDefinition() : undefined;
  const value =
    businessFormat === 'report' ? (
      reportDefinition ? (
        <Report
          report={reportDefinition}
          data={data as ReportData}
          noReportWrapper
        />
      ) : null
    ) : (
      formatValue(
        translatedValue as string | number,
        unit,
        businessFormat,
        format
      )
    );

  const Wrapper =
    isBrowser && link && rowData
      ? ({ children }: PropsWithChildren) => (
          <Link
            to={() => link(rowData)}
            onMouseDown={onLinkClick ? () => onLinkClick(rowData) : undefined}
          >
            {children}
          </Link>
        )
      : Fragment;

  const tooltipData =
    typeof window !== 'undefined' && getTooltipContent && rowData
      ? getTooltipContent(rowData)
      : null;

  const reportSectionRatio = reportContext
    ? reportContext.sectionRatio ?? 0
    : 0;
  const cellContextValue = useMemo(
    () => ({
      columnConfig: headerConfig,
      columnPrintWidth: headerConfig
        ? calculateColumnPrintWidth(headerConfig, reportSectionRatio)
        : 0
    }),
    [headerConfig, reportSectionRatio]
  );

  const TooltipWrapper = tooltipData
    ? ({ children }: { children: JSX.Element }) => (
        <Tippy tooltipContent={tooltipData} noWrapper>
          {children}
        </Tippy>
      )
    : Fragment;

  const color = rowData ? getColor?.(rowData) : null;

  return (
    <CsvTableCellContext.Provider value={cellContextValue}>
      <TooltipWrapper>
        <StyledCsvTableCell
          align={alignment || 'right'}
          borderLeft={borderLeft}
          borderRight={borderRight}
          rowSpan={rowSpan}
          colSpan={colSpan}
          whiteSpace={noWrap ? WhiteSpace.noWrap : undefined}
          color={mapColor(color ?? '#4a4a4a')}
          verticalAlignment={verticalAlignment}
        >
          <Wrapper>
            {businessFormat === 'icon' ? (
              <IconWrapper inheritColor align={alignment || 'right'}>
                {value}
              </IconWrapper>
            ) : (
              <Text
                size={TextSize.inherit}
                monoSpace
                whiteSpace={
                  shouldWrap(unit, businessFormat) && !noWrap
                    ? undefined
                    : WhiteSpace.noWrap
                }
                inheritColor
              >
                {value}
              </Text>
            )}
          </Wrapper>
        </StyledCsvTableCell>
      </TooltipWrapper>
    </CsvTableCellContext.Provider>
  );
});
