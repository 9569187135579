import { FormattedMessage, IntlProvider } from 'react-intl';
import {
  ContentBox,
  ContentBoxHead,
  IntegratorRoot
} from '@yarmill/components';

import styled from 'styled-components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useRootStore } from '../app/root-store-context';
import { useEffect } from 'react';

const DisabledInstanceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredContentBox = styled(ContentBox)`
  max-width: 720px;
  white-space: pre-wrap;
`;

export function DisabledInstance(): JSX.Element {
  const rootStore = useRootStore();
  const intlStore = rootStore.intlStore;

  useEffect(() => {
    if (intlStore.version === 'local') {
      void intlStore.loadTranslations(intlStore.locale);
    }
  }, [intlStore]);

  return (
    <IntlProvider locale={intlStore.locale} messages={intlStore.messages}>
      <IntegratorRoot>
        <DisabledInstanceWrapper>
          <CenteredContentBox>
            <ContentBoxHead>
              <FormattedMessage id="disabledInstance.header" />
            </ContentBoxHead>
            <FormattedHTMLMessage id="disabledInstance.text" />
          </CenteredContentBox>
        </DisabledInstanceWrapper>
      </IntegratorRoot>
    </IntlProvider>
  );
}
