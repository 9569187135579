import { UserId, UserRole } from '../users/types';

export enum AnnouncementSeverity {
  'info' = 'info',
  'warning' = 'warning',
  'danger' = 'danger'
}

export enum AnnouncementType {
  'alert' = 'alert',
  'image' = 'image',
  'notification-top' = 'notification-top',
  'notification-top-left' = 'notification-top-left',
  'notification-top-right' = 'notification-top-right',
  'notification-bottom' = 'notification-bottom',
  'notification-bottom-left' = 'notification-bottom-left',
  'notification-bottom-right' = 'notification-bottom-right'
}

export interface Announcement {
  code: AnnouncementCode;
  type: AnnouncementType;
  severity: AnnouncementSeverity;
  displays: number;
  permissions?: {
    roles?: UserRole[];
    users?: UserId[];
  };
}

export type AnnouncementCode = string;

export const ANNOUNCEMENT_PERSIST_PREFIX = 'announcement.';
