import { LogLevel, LogEntry } from './types';
import { instance } from '../../api/api-mapping';
import { GlobalIntl } from '../../intl/global-intl-provider';
import { isLogLevelEnabled } from './is-log-level-enabled';
import { format } from './format';
import { Logtail } from '@logtail/browser';
import { RootStore } from '../../app/mobx/root-store';
import { isBrowser } from '@yarmill/components';

export class HttpLogger {
  private readonly level: LogLevel = 'warn';
  private readonly timeout = isBrowser ? 2500 : 0;
  private queue: LogEntry[] = [];
  private readonly logtail = process.env.REACT_APP_LOGGER_TOKEN
    ? new Logtail(process.env.REACT_APP_LOGGER_TOKEN ?? '', {
        sendLogsToBetterStack: process.env.NODE_ENV === 'production'
      })
    : null;
  private rootStore: RootStore | null = null;

  public constructor() {
    this.scheduleSendQueue();
  }

  public sendHttp(
    message: string | undefined,
    level: LogLevel,
    ...optionalParams: any[]
  ): void {
    if (message && isLogLevelEnabled(level, this.level)) {
      const formattedMessage = format(message, ...optionalParams);
      const url = isBrowser ? window.location.href : 'print';
      const scope = isBrowser ? window.location.pathname : 'print';

      const event: LogEntry = {
        ...this.commonEventParams,
        scope,
        level,
        timestamp: new Date().getTime(),
        message: formattedMessage,
        url
      };

      this.queue.push(event);
    }
  }

  public get commonEventParams() {
    const appVersion =
      isBrowser &&
      (window.location.hostname.includes('localhost') ||
        window.location.hostname.includes('friendly-hermann-eceb82') ||
        window.location.hostname.startsWith('test'))
        ? 'test'
        : process.env.APP_VERSION;

    return {
      appVersion: appVersion ?? 'test',
      instanceCode: instance,
      language: GlobalIntl?.locale ?? '',
      browserLanguage: isBrowser ? window.navigator.language : '',
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
      user_id: this.rootStore?.currentUserStore?.id,
      user_name: this.rootStore?.currentUserStore?.displayName,
      user_role: this.rootStore?.currentUserStore?.role
    };
  }

  public setRootStore(rootStore: RootStore): void {
    this.rootStore = rootStore;
  }

  private scheduleSendQueue(): void {
    if (this.timeout) {
      setTimeout(() => {
        this.sendQueue().catch(e => console.error('cannot send log events', e));
        this.scheduleSendQueue();
      }, this.timeout);
    }
  }

  public sendQueue(): Promise<void> {
    const queueCopy = this.queue.slice();
    this.queue = [];

    if (queueCopy.length > 0) {
      return this.sendMessage(queueCopy);
    }

    return Promise.resolve();
  }

  private async sendMessage(queue: LogEntry[]): Promise<void> {
    queue.forEach(event => {
      const { level, message, ...details } = event;
      this.logtail?.[level](message, details);
    });
    await this.logtail?.flush();
  }
}
