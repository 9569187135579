import { createRoot } from 'react-dom/client';
import { App } from './app/app';
import { IntlProvider } from './intl/intl-provider';
import packageJson from '../package.json';

import './intl/register-polyfill';
import { showNewVersionAnnouncement } from './announcement/new-version';
import { registerVersionWorker } from './registerVersionWorker';
import { RootStore } from './app/mobx/root-store';
import { RootStoreContext } from './app/root-store-context';
import { devMode, disabledInstances, instance } from './api/api-mapping';
import { DisabledInstance } from './disabled-instance';
import { configure } from 'mobx';
import { FatalError } from './fatal-error/fatal-error';
import { ErrorBoundary } from '@yarmill/components';
import { ErrorInfo } from 'react';
import { debugValue } from './utils/debug-value';
import { initGlobalYtdNamespace } from './utils/initGlobalYtdNamespace';
import { LoggerContext } from './utils/logger/logger-context';
import { Logger } from './utils/logger/logger';
import { setGlobalLogger } from './utils/logger/setGlobalLogger';

initGlobalYtdNamespace();
configure({
  enforceActions: 'never'
});

const target = document.getElementById('yarmill-diary-app');

// Unsupported browser
if (!target) {
  throw new Error('Unsupported browser');
}

const logger = new Logger();
setGlobalLogger(logger);
const root = createRoot(target);
let rootStore: RootStore;

const FatalErrorComponent = () => (
  <LoggerContext.Provider value={logger}>
    <RootStoreContext.Provider value={rootStore}>
      <IntlProvider>
        <FatalError />
      </IntlProvider>
    </RootStoreContext.Provider>
  </LoggerContext.Provider>
);
const renderFatalError = () => root.render(<FatalErrorComponent />);

function logError(e: Error, errorInfo?: ErrorInfo): void {
  logger.error(e.message, e.name);
}

const render = (AppComponent: typeof App) =>
  root.render(
    <LoggerContext.Provider value={logger}>
      <ErrorBoundary fallback={<FatalErrorComponent />} onCatch={logError}>
        <RootStoreContext.Provider value={rootStore}>
          <IntlProvider>
            <AppComponent history={rootStore.historyService.history} />
          </IntlProvider>
        </RootStoreContext.Provider>
      </ErrorBoundary>
    </LoggerContext.Provider>
  );

try {
  rootStore = new RootStore(logger);
  debugValue(rootStore, 'rootStore');

  registerVersionWorker(packageJson.version, showNewVersionAnnouncement);

  const renderDisabledInstance = () => {
    root.render(
      <LoggerContext.Provider value={logger}>
        <RootStoreContext.Provider value={rootStore}>
          <DisabledInstance />
        </RootStoreContext.Provider>
      </LoggerContext.Provider>
    );
  };

  if (disabledInstances.includes(instance) && !devMode) {
    renderDisabledInstance();
  } else {
    render(App);
  }
} catch (e: any) {
  logError(e);
  renderFatalError();
}

if (module.hot) {
  module.hot.accept('./app/app', () => {
    const NextApp = require('./app/app').App;
    render(NextApp);
  });
}
