import {
  BusinessFormatterOptions,
  ChartReportDataItem,
  ChartReportDataItemKey,
  ConfigOverride,
  DataDefinitionV2,
  LabelPosition,
  ReportItem
} from '../types';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  fillStringTemplate,
  formatValueByBusinessFormat,
  mapColor
} from '../utils';
import {
  AxisConfig,
  AxisValue,
  CurveType,
  GridLinesConfig,
  LineStyle,
  TooltipValue,
  XAxisConfig,
  YAxisConfig
} from '@yarmill/components';
import { GlobalIntl } from '../../intl/global-intl-provider';
import { ReactNode } from 'react';
import { lowerCaseFirstCharacter } from '../../utils/lower-case-first-character';
import { GlobalLogger } from '../../utils/logger/setGlobalLogger';

export class XyChartStore {
  private readonly _pageCode: string;
  private readonly _item: ReportItem;

  @observable
  private configOverride: ConfigOverride | undefined;

  constructor(item: ReportItem, pageCode: string) {
    this._item = item;
    this._pageCode = pageCode;
    makeObservable(this);
  }

  @computed
  public get axisX(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForX');
  }

  @computed
  public get axisY(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForY');
  }

  @computed
  public get dataColumns(): DataDefinitionV2[] {
    return this.dataDefinition.filter(def => def.Purpose === 'DataValue');
  }

  @computed
  public get dataColumnsByName(): Record<string, DataDefinitionV2> {
    return Object.fromEntries(this.dataColumns.map(def => [def.Name, def]));
  }

  @computed
  public get allColumnsByName(): Record<string, DataDefinitionV2> {
    return Object.fromEntries(this.dataDefinition.map(def => [def.Name, def]));
  }

  @computed
  public get color(): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def => def.Purpose === 'ExtraValue' && def.IsColor
      ) || this.dataDefinition.find(def => def.Purpose === 'MultiLineColor')
    );
  }

  @computed
  public get width(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsWidth
    );
  }

  @computed
  public get category(): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def => def.Purpose === 'ExtraValue' && def.IsCategory
      ) || this.dataDefinition.find(def => def.Purpose === 'MultiLineCategory')
    );
  }

  @computed
  public get lineStyle(): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def => def.Purpose === 'ExtraValue' && def.IsLineStyle
      ) || this.dataDefinition.find(def => def.Purpose === 'MultiLineLineStyle')
    );
  }

  @computed
  public get labelColor(): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def => def.Purpose === 'ExtraValue' && def.IsLabelColor
      ) ||
      this.dataDefinition.find(def => def.Purpose === 'MultiLineLabelColor')
    );
  }

  @computed
  public get lineOrder(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsLineOrder
    );
  }

  @computed
  public get markerSize(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsMarkerSize
    );
  }

  @computed
  public get markerStrokeWidth(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsMarkerStrokeWidth
    );
  }

  @computed
  public get markerStrokeColor(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsMarkerStrokeColor
    );
  }

  @computed
  public get strokeWidth(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsStrokeWidth
    );
  }

  @computed
  public get showLabels(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsShowLabels
    );
  }

  @computed
  public get opacity(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' && (def.IsStrokeOpacity || def.IsOpacity)
    );
  }

  @computed
  public get labelAngle(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsLabelAngle
    );
  }

  @computed
  public get strokeDasharray(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsStrokeDasharray
    );
  }

  @computed
  public get curveType(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsCurveType
    );
  }

  @computed
  public get tooltipLabel(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsTooltipLabel
    );
  }

  @computed
  public get hideInTooltip(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsHideInTooltip
    );
  }

  public readonly getShowLabels = (
    item: ChartReportDataItem,
    key?: string
  ): boolean => {
    const showLabelsKey = this.showLabels?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return showLabelsKey
      ? Boolean(item[showLabelsKey])
      : def?.ShowLabels ?? Boolean(this.params?.ShowLabels);
  };

  @computed
  public get labelPosition(): LabelPosition {
    return this.params?.LabelPosition || 'outside';
  }

  @computed
  public get showAxisXLabels(): boolean {
    return Boolean(this.params?.ShowAxisXLabels);
  }

  @computed
  public get showAxisXLabelsInTooltip(): boolean {
    return Boolean(this.params?.ShowAxisXLabelsInTooltip);
  }

  @computed
  public get tooltipTableLayout(): string | undefined {
    return this.axisX?.TooltipLayout || undefined;
  }

  @computed
  public get showTooltipTableColumnLabels(): boolean {
    return Boolean(this.axisX?.ShowTooltipColumnLabels);
  }

  @computed
  public get showAxisYLabels(): boolean {
    return Boolean(this.params?.ShowAxisYLabels);
  }

  @computed
  public get showAxisYLabelsInTooltip(): boolean {
    return Boolean(this.params?.ShowAxisYLabelsInTooltip);
  }

  @computed
  public get height(): number | undefined {
    return this._item.Params?.Height;
  }

  public readonly getMarkerSize = (
    item: ChartReportDataItem,
    key: string
  ): number | undefined => {
    const markerSizeKey = this.markerSize?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return markerSizeKey ? Number(item[markerSizeKey]) : def?.MarkerSize;
  };

  public readonly getMarkerStrokeWidth = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | string | undefined => {
    const markerStrokeWidthKey = this.markerStrokeWidth?.Name;

    return markerStrokeWidthKey
      ? (item[markerStrokeWidthKey] as number)
      : def?.MarkerStrokeWidth ?? undefined;
  };

  public readonly getMarkerStrokeColor = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): string | undefined => {
    const markerStrokeColorKey = this.markerStrokeColor?.Name;

    return markerStrokeColorKey
      ? (item[markerStrokeColorKey] as string)
      : def?.MarkerStrokeColor ?? undefined;
  };

  public readonly getStrokeWidth = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | undefined => {
    const strokeWidthKey = this.strokeWidth?.Name;

    return strokeWidthKey ? (item[strokeWidthKey] as number) : def?.StrokeWidth;
  };

  public readonly getOpacity = (
    item: ChartReportDataItem,
    key?: string
  ): number | undefined => {
    const opacityKey = this.opacity?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return opacityKey
      ? (item[opacityKey] as number)
      : def?.StrokeOpacity ?? def?.Opacity;
  };

  public readonly getLabelAngle = (
    item: ChartReportDataItem,
    key?: string
  ): number => {
    const labelAngleKey = this.labelAngle?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return (
      (labelAngleKey ? (item[labelAngleKey] as number) : def?.LabelAngle) ?? 0
    );
  };

  public readonly getStrokeDasharray = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | undefined => {
    const strokeDasharrayKey = this.strokeDasharray?.Name;

    return strokeDasharrayKey
      ? (item[strokeDasharrayKey] as number)
      : def?.StrokeDasharray;
  };

  public readonly getCurveType = (
    item: ChartReportDataItem,
    key: string
  ): CurveType => {
    const curveTypeKey = this.curveType?.Name;
    const line = this.dataColumnsByName[key];
    let curveType = line.CurveType;

    if (curveTypeKey) {
      curveType = String(item[curveTypeKey]) as CurveType | undefined;
    }

    return curveType || 'monotoneX';
  };

  public readonly getColor = (
    item: ChartReportDataItem,
    key: string
  ): string => {
    const colorKey = this.color?.Name;
    const def = this.dataColumnsByName[key];
    let color = def?.Color;

    if (colorKey) {
      color = String(item[colorKey]);
    }

    return color ? mapColor(color) : '';
  };

  public readonly getWidth = (
    item: ChartReportDataItem,
    key: string
  ): number => {
    const widthKey = this.width?.Name;
    const def = this.dataColumnsByName[key];
    let width = def?.Width;

    if (widthKey) {
      width = item[widthKey];
    }

    return width !== undefined ? Number(width) : 1;
  };

  public readonly getLabelColor = (
    item: ChartReportDataItem,
    key: string
  ): string => {
    const labelColorKey = this.labelColor?.Name;
    const def = this.dataColumnsByName[key];
    let color = def?.LabelColor;

    if (labelColorKey) {
      color = String(item[labelColorKey]);
    }

    return mapColor(color);
  };

  public readonly getLineOrder = (item: ChartReportDataItem): number => {
    const lineOrderKey = this.lineOrder?.Name;

    if (lineOrderKey) {
      return Number(item[lineOrderKey]);
    }

    return 0;
  };

  public readonly getLineStyle = (
    item: ChartReportDataItem,
    key: string
  ): LineStyle => {
    const lineStyleKey = this.lineStyle?.Name;
    const def = this.dataColumnsByName[key];
    let lineStyle = def?.LineStyle;

    if (lineStyleKey) {
      lineStyle = String(item[lineStyleKey]) as LineStyle | undefined;
    }

    return lineStyle || 'default';
  };

  public readonly getXValue = <
    Type extends ChartReportDataItem[ChartReportDataItemKey]
  >(
    item: ChartReportDataItem
  ): Type => {
    const key = this.axisX?.Name ?? '';
    if (!key) {
      GlobalLogger.error(
        'Reporting configuration error',
        `Page: ${this._pageCode}`,
        `Report: ${this._item.Code}`,
        'Missing value for x key'
      );
    }

    return item[key] as Type;
  };

  public readonly getYValue = <
    Type extends ChartReportDataItem[ChartReportDataItemKey]
  >(
    item: ChartReportDataItem
  ): Type => {
    const key = this.axisY?.Name ?? '';
    if (!key) {
      GlobalLogger.error(
        'Reporting configuration error',
        `Page: ${this._pageCode}`,
        `Report: ${this._item.Code}`,
        'Missing value for y key'
      );
    }

    return item[key] as Type;
  };

  @computed
  public get baseConfig() {
    return {
      getXValue: this.getXValue,
      getYValue: this.getYValue,
      getColor: this.getColor,
      getLabelColor: this.getLabelColor,
      getMarkerSize: this.getMarkerSize,
      labelPosition: this.labelPosition,
      getCurveType: this.getCurveType,
      axisConfigs: this.axisConfigs
    };
  }

  @computed
  public get tooltipTableLayoutConfig() {
    const tableLayout = this.tooltipTableLayout;
    const showColumnLabels = this.showTooltipTableColumnLabels;

    return {
      tableLayout,
      showColumnLabels,
      tableColumnsLabels:
        tableLayout && showColumnLabels
          ? Object.fromEntries(
              tableLayout.split(' ').map(key => [
                key,
                GlobalIntl.formatMessage({
                  id:
                    this.allColumnsByName[key]?.Label ||
                    this.allColumnsByName[key]?.Name ||
                    key
                })
              ])
            )
          : undefined
    };
  }

  public mapTooltipValues(
    item: ChartReportDataItem,
    axis: 'x' | 'y'
  ): TooltipValue[] {
    const axisDefinition = axis === 'x' ? this.axisX : this.axisY;
    const tooltipItemLabel = axisDefinition?.TooltipItemLabel;
    const tooltipItemValue = axisDefinition?.TooltipItemValue;
    const tableColumns = axisDefinition?.TooltipLayout?.split(' ');

    const replacementValues =
      tooltipItemLabel || tooltipItemValue
        ? this.formatItemByDataTypes(item)
        : {};

    const getReferencedLabelValue = (columnName: string): string => {
      const def = this.allColumnsByName[columnName];
      if (!def) {
        return '';
      }
      const value = item[def.Name];

      return formatValueByBusinessFormat(
        value,
        def.BusinessFormat,
        def.Format,
        {
          forceString: true
        }
      ) as string;
    };

    return this.dataColumns
      .filter(def => !def.HideInTooltip)
      .map(def => ({
        label: def.TooltipLabelRef
          ? getReferencedLabelValue(def.TooltipLabelRef)
          : GlobalIntl.formatMessage(
              {
                id: tooltipItemLabel
                  ? tooltipItemLabel
                  : def.Label ||
                    `reporting.${lowerCaseFirstCharacter(
                      this._pageCode
                    )}.${lowerCaseFirstCharacter(
                      this._item.Code
                    )}.${lowerCaseFirstCharacter(def.Name)}`
              },
              replacementValues
            ),
        value: tooltipItemValue
          ? fillStringTemplate(tooltipItemValue, replacementValues)
          : formatValueByBusinessFormat(
              item[def.Name],
              def.BusinessFormat,
              def.Format
            ),
        color: mapColor(this.getColor(item, def.Name)),
        originalValue: item[def.Name],
        key: def.Name,
        tableValues: tableColumns?.length
          ? this.mapTooltipTableLayoutValues(item, tableColumns)
          : undefined
      }))
      .filter(i => i.originalValue !== null)
      .sort((a, b) =>
        this.params?.SortBy === 'category'
          ? 0
          : Number(b.originalValue || 0) - Number(a.originalValue || 0)
      );
  }

  public mapMultiLineTooltipValues(
    items: ChartReportDataItem[],
    axis: 'x' | 'y'
  ): TooltipValue[] {
    const dataColumn = this.dataColumns[0];
    const hideInTooltipDef = this.hideInTooltip;
    const axisDefinition = axis === 'x' ? this.axisX : this.axisY;

    if (!dataColumn || dataColumn.HideInTooltip || !axisDefinition) {
      return [];
    }

    const tooltipItemLabel = axisDefinition?.TooltipItemLabel;
    const tooltipItemValue = axisDefinition?.TooltipItemValue;
    const tableColumns = axisDefinition?.TooltipLayout?.split(' ');

    return items
      .filter(item => {
        return !hideInTooltipDef || !item[hideInTooltipDef.Name];
      })
      .map(item => {
        const replacementValues =
          tooltipItemLabel || tooltipItemValue
            ? this.formatItemByDataTypes(item)
            : {};

        return {
          label: tooltipItemLabel
            ? GlobalIntl.formatMessage(
                {
                  id: tooltipItemLabel
                },
                replacementValues
              )
            : String(item[this.category?.Name ?? '']),
          value: tooltipItemValue
            ? fillStringTemplate(tooltipItemValue, replacementValues)
            : formatValueByBusinessFormat(
                item[dataColumn.Name],
                dataColumn.BusinessFormat,
                dataColumn.Format
              ),
          color: mapColor(this.getColor(item, dataColumn.Name)),
          originalValue: item[dataColumn.Name],
          axisValue: item[axisDefinition.Name],
          tableValues: tableColumns?.length
            ? this.mapTooltipTableLayoutValues(item, tableColumns)
            : undefined
        };
      })
      .filter(i => i.originalValue !== null)
      .sort(
        (a, b) => Number(b.originalValue || 0) - Number(a.originalValue || 0)
      );
  }

  public formatAxisValue(
    tickValue: AxisValue,
    axis: 'x' | 'y',
    options?: BusinessFormatterOptions,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string {
    const def = axis === 'x' ? this.axisX : this.axisY;

    if (!def) {
      return String(tickValue);
    }

    if (def.TranslateValue) {
      let values = {};

      if (getItem) {
        const item = getItem(def.Name, tickValue);
        if (item) {
          values = this.formatItemByDataTypes(item);
        }
      }

      return GlobalIntl.formatMessage({ id: String(tickValue) }, values);
    }

    return formatValueByBusinessFormat(
      tickValue as string | number,
      def.BusinessFormat,
      def.Format,
      options
    ) as string;
  }

  public formatTooltipLabel(
    tickValue: AxisValue,
    axis: 'x' | 'y',
    item?: ChartReportDataItem
  ): string {
    const axisDef = axis === 'x' ? this.axisX : this.axisY;
    const tooltipLabelDefinition = this.tooltipLabel;

    if (!axisDef) {
      return String(tickValue);
    }

    if (tooltipLabelDefinition?.TooltipLabel || axisDef?.TooltipLabel) {
      const replacementValues = this.formatItemByDataTypes(item ?? {});

      return GlobalIntl.formatMessage(
        { id: tooltipLabelDefinition?.TooltipLabel || axisDef.TooltipLabel },
        replacementValues
      );
    }

    const value =
      tooltipLabelDefinition && item
        ? item[tooltipLabelDefinition.Name]
        : tickValue;

    if (axisDef.TranslateValue) {
      const replacementValues = this.formatItemByDataTypes(item ?? {});
      return GlobalIntl.formatMessage(
        { id: String(tickValue) },
        replacementValues
      );
    }

    return formatValueByBusinessFormat(
      value as number | string,
      tooltipLabelDefinition?.BusinessFormat ??
        axisDef.TooltipLabelBusinessFormat ??
        axisDef.BusinessFormat,
      tooltipLabelDefinition?.Format ??
        axisDef.TooltipLabelFormat ??
        axisDef.Format,
      { forceString: true }
    ) as string;
  }

  public readonly formatAxisXTick = (
    tickValue: AxisValue,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string => {
    return this.formatAxisValue(
      tickValue,
      'x',
      {
        forceString: true
      },
      getItem
    ) as string;
  };

  public readonly formatAxisYTick = (
    tickValue: AxisValue,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string => {
    return this.formatAxisValue(
      tickValue,
      'y',
      {
        forceString: true
      },
      getItem
    ) as string;
  };

  @computed
  public get axisConfigs(): AxisConfig<AxisValue, AxisValue>[] {
    const x: XAxisConfig<AxisValue> = {
      axis: 'x',
      tickAngle: this.axisX?.AxisTickAngle ?? 0,
      numberOfTicks: this.axisX?.AxisTicksCount,
      formatTick: this.formatAxisXTick,
      showTickLabels:
        this.axisX?.ShowLabels ?? this.params?.ShowAxisXLabels ?? true,
      hideAxisLine: this.axisX?.HideAxisLine,
      type: this.axisXType,
      translateValues: this.axisX?.TranslateValue
    };
    const y: YAxisConfig<AxisValue> = {
      axis: 'y',
      tickAngle: this.axisY?.AxisTickAngle ?? 0,
      numberOfTicks: this.axisY?.AxisTicksCount,
      formatTick: this.formatAxisYTick,
      showTickLabels:
        this.axisY?.ShowLabels ?? this.params?.ShowAxisYLabels ?? false,
      hideAxisLine: this.axisY?.HideAxisLine,
      type: this.axisYType,
      translateValues: this.axisY?.TranslateValue
    };

    return [x, y];
  }

  @computed
  public get gridLinesConfig(): GridLinesConfig | undefined {
    return this.params?.GridLinesConfig;
  }

  @action
  public setConfigOverride(config: ConfigOverride | undefined) {
    this.configOverride = config;
  }

  @computed
  public get noDataMessage(): string | null | undefined {
    return this.params?.NoDataMessage;
  }

  private formatItemByDataTypes(
    item: ChartReportDataItem
  ): Record<string, string> {
    const entries = Object.entries(item).map(([key, value]) => {
      const def = this.allColumnsByName[key];

      return [
        key,
        def
          ? formatValueByBusinessFormat(value, def.BusinessFormat, def.Format, {
              forceString: true
            })
          : value
      ];
    });

    return Object.fromEntries(entries);
  }

  private mapTooltipTableLayoutValues(
    item: ChartReportDataItem,
    tableColumns: string[]
  ): Record<string, ReactNode> {
    return Object.fromEntries(
      tableColumns.map(columnKey => {
        const def = this.allColumnsByName[columnKey];
        if (!def) {
          return [columnKey, ''];
        }
        const val = item[def.Name];

        return [
          columnKey,
          formatValueByBusinessFormat(val, def.BusinessFormat, def.Format)
        ];
      })
    );
  }

  @computed
  private get dataDefinition(): DataDefinitionV2[] {
    return this.configOverride?.DataDefinition ?? this._item.DataDefinition;
  }

  @computed
  private get params() {
    return this.configOverride?.Params ?? this._item.Params;
  }

  @computed
  private get axisXType() {
    return this._item.Kind === 'ContinuousChart' ||
      this._item.Kind === 'TimeChart' ||
      this._item.Kind === 'HorizontalClusteredColumnChart' ||
      this._item.Kind === 'HorizontalStackedColumnChart'
      ? 'continuous'
      : 'categorical';
  }

  @computed
  private get axisYType() {
    return this._item.Kind === 'HorizontalClusteredColumnChart' ||
      this._item.Kind === 'HorizontalStackedColumnChart'
      ? 'categorical'
      : 'continuous';
  }
}
