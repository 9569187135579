import { ReactElement, useCallback } from 'react';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { useEvidenceFileUpload } from '../hooks/use-evidence-file-upload';
import { observer } from 'mobx-react-lite';
import { FileAttribute } from './file-attribute';

type Variant = 'table-form' | 'default';

interface EvidenceFileAttributeProps {
  readonly attribute: EvidenceAttachmentStore;
  readonly hideAdd?: boolean;
  readonly variant?: Variant;
  readonly autoSave?: boolean;
}

export const EvidenceFileAttribute = observer(function EvidenceFileAttribute({
  attribute,
  hideAdd = false,
  variant = 'default',
  autoSave = true
}: EvidenceFileAttributeProps): ReactElement {
  const files = attribute.attachments;
  const isCurrentUserAllowedToWrite =
    attribute.objectDataStore.isCurrentUserAllowedToWrite;
  const [inputRef, onChange] = useEvidenceFileUpload(attribute);
  const save = useCallback(
    async () => await attribute.objectDataStore.syncValue(),
    [attribute]
  );

  return (
    <FileAttribute
      attachments={files}
      autoSave={autoSave}
      hideAdd={hideAdd}
      inputRef={inputRef}
      isCurrentUserAllowedToWrite={isCurrentUserAllowedToWrite}
      onChange={onChange}
      removeFile={attribute.removeAttachment}
      save={save}
      variant={variant}
    />
  );
});
