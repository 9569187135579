import { ReportBackgroundProvider, Text, TextSize } from '@yarmill/components';
import { ParentSize } from '@visx/responsive';
import { AsyncStatus } from '../api/mobx/request-store';
import { Fragment, ReactElement, useContext } from 'react';
import { ChartLoader } from '../workouts/chart-loader';
import { FormattedMessage } from 'react-intl';
import { getReportWidth } from './utils';
import { observer } from 'mobx-react-lite';
import { ReportContentBox } from './report-content-box';
import { CsvTableCellContext } from '../csv-table/csv-table-cell-context';
import { ReportingSectionContext } from './section';

interface ReportWrapperProps {
  readonly hasData: boolean;
  readonly height: number;
  readonly status: AsyncStatus;
  readonly children: (width: number) => JSX.Element;
  readonly sectionRatio?: number;
  readonly noDataMessage?: string | null;
  readonly aspectRatio?: number;
  readonly backgroundImage?: string;
  readonly noReportWrapper?: boolean;
}

export const ReportWrapper = observer(function ReportWrapper(
  props: ReportWrapperProps
): ReactElement | null {
  const {
    hasData,
    status,
    height,
    children,
    sectionRatio,
    noDataMessage,
    aspectRatio,
    backgroundImage,
    noReportWrapper
  } = props;
  const tableReportCellContext = useContext(CsvTableCellContext);
  const sectionContext = useContext(ReportingSectionContext);

  if (status === AsyncStatus.resolved && noDataMessage === null && !hasData) {
    return null;
  }

  const Wrapper = noReportWrapper ? Fragment : ReportContentBox;

  return (
    <Wrapper key={sectionRatio}>
      <ReportBackgroundProvider
        aspectRatio={aspectRatio}
        backgroundImage={backgroundImage}
      >
        <ParentSize debounceTime={0}>
          {({ width }) =>
            status === AsyncStatus.idle ? (
              <Fragment />
            ) : status === AsyncStatus.pending ? (
              <ChartLoader
                width={width}
                height={aspectRatio && width ? width / aspectRatio : height}
              />
            ) : status === AsyncStatus.rejected ? (
              <Text size={TextSize.s14}>
                <FormattedMessage id="reporting.data.error" />
              </Text>
            ) : hasData ? (
              children(
                getReportWidth(
                  width,
                  sectionRatio,
                  tableReportCellContext,
                  sectionContext.layout
                )
              )
            ) : (
              <Text size={TextSize.s14}>
                <FormattedMessage id={noDataMessage ?? 'reporting.noData'} />
              </Text>
            )
          }
        </ParentSize>
      </ReportBackgroundProvider>
    </Wrapper>
  );
});
