import { CsvTableHeader, CsvTableRowData } from '../types';
import { formatValueByBusinessFormat } from '../../reporting/utils';
import { Logger } from '../../utils/logger/logger';

export function formatRowDataByBusinessFormat(
  row: CsvTableRowData,
  def: CsvTableHeader[],
  logger: Logger
) {
  try {
    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        const column = def.find(c => c.ColumnName === key);
        if (!column || column.BusinessFormat === 'report') {
          return [key, value];
        } else {
          return [
            key,
            formatValueByBusinessFormat(
              value as string | number,
              column.BusinessFormat ?? undefined,
              column.Format
            )
          ];
        }
      })
    );
  } catch (e: unknown) {
    logger.error('Unable to format table row data', e);
    return row;
  }
}
