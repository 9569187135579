import {
  Dispatch,
  FormEvent,
  FormEventHandler,
  RefObject,
  SetStateAction,
  useCallback,
  useState
} from 'react';
import { useRootStore } from '../../app/root-store-context';
import { YollandaInteractionId } from '../types';
import { useLogger } from '../../utils/logger/logger-context';
import { ServerError } from '../../app/app-types';
import { useIntl } from 'react-intl';

export function useSubmitHandler(
  layoutRef: RefObject<HTMLDivElement>,
  setShowLoadingMessageFallback: Dispatch<SetStateAction<boolean>>
): [string, YollandaInteractionId, boolean, FormEventHandler] {
  const rootStore = useRootStore();
  const intl = useIntl();
  const yollandaService = rootStore.yollandaService;
  const [isPending, setIsPending] = useState(false);
  const [streamedMessage, setStreamedMessage] = useState<string>('');
  const [interactionId, setInteractionId] = useState<string>('');
  const logger = useLogger();

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsPending(true);
      setStreamedMessage('');
      setInteractionId('');
      setShowLoadingMessageFallback(false);
      const values = new FormData(e.currentTarget);
      const query = (values.get('term') as string) ?? '';

      if (!query) {
        return;
      }
      const timer = new Date().getMilliseconds();

      yollandaService.askYollanda(query).then(response => {
        if (!response.body || !(response.body instanceof ReadableStream)) {
          logger.error('Response is not a readable stream');
          return;
        }
        const stream = response.body;
        const reader = stream.getReader();
        const interactionId =
          response.headers.get('x-yollanda-interaction-id') ??
          response.headers.get('X-Yollanda-Interaction-Id');

        if (interactionId) {
          setInteractionId(interactionId);
        }

        setIsPending(false);

        const now = new Date().getMilliseconds();
        if (now - timer < 15000) {
          setShowLoadingMessageFallback(true);
          setTimeout(() => {
            setShowLoadingMessageFallback(false);
          }, 1500);
        }

        const parseMessage = (msg: string): string => {
          const parsedMessage = JSON.parse(msg.trim());

          if ('message' in parsedMessage) {
            return parsedMessage.message as string;
          } else if ('error' in parsedMessage || 'Id' in parsedMessage) {
            const error = (
              'error' in parsedMessage ? parsedMessage.error : parsedMessage
            ) as ServerError;
            const id = error.Id;
            const values = error.Values || {};

            if (id) {
              logger.error('Yollanda error', error.Description);

              return intl.formatMessage({ id }, values);
            } else {
              logger.error('Yollanda received unknown message', msg);
              return intl.formatMessage({ id: 'yollanda.unknownError' });
            }
          } else {
            return '';
          }
        };

        const readChunk = async () => {
          const { done, value } = await reader.read();

          if (done) {
            reader.releaseLock();
          } else {
            let stringValue: string = '';
            try {
              stringValue = new TextDecoder().decode(value);
              const splitted = stringValue.split('\n');

              const message = splitted
                .map(msg => (msg ? parseMessage(msg) : ''))
                .join('');

              setStreamedMessage(m => m + message ?? '');
              layoutRef.current?.parentElement?.scrollBy({ top: 20 });
            } catch (e) {
              console.groupCollapsed(e);
              logger.error(e, value);
              console.log('Received value', value);
              console.log('String value', stringValue);
              console.groupEnd();
            }
            readChunk();
          }
        };

        readChunk();
      });
    },
    [setShowLoadingMessageFallback, layoutRef, yollandaService, intl, logger]
  );

  return [streamedMessage, interactionId, isPending, handleSubmit];
}
