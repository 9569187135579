import {
  InactiveInstanceContentBox,
  InactiveInstanceWrapper,
  Text,
  TextSize
} from '@yarmill/components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useEffect } from 'react';
import { useRootStore } from '../app/root-store-context';

export function InactiveInstance() {
  const rootStore = useRootStore();
  const intlStore = rootStore.intlStore;

  useEffect(() => {
    if (intlStore.version === 'local') {
      void intlStore.loadTranslations(intlStore.locale);
    }
  }, [intlStore]);

  return (
    <InactiveInstanceWrapper>
      <InactiveInstanceContentBox>
        <Text size={TextSize.s14}>
          <FormattedHTMLMessage id="auth.login.inactiveInstance" />
        </Text>
      </InactiveInstanceContentBox>
    </InactiveInstanceWrapper>
  );
}
