import {
  Icon,
  IconSize,
  ButtonAppearance,
  ExportButton,
  ExternalIcon
} from '@yarmill/components';
import { Popover } from '../components/popover';
import { ExportDropdown } from './export-dropdown';
import { useIntl } from 'react-intl';
import { ExportItem } from './types';

export interface ExportSeasonProps {
  exportItems: ExportItem[];
}

export function Export(props: ExportSeasonProps): JSX.Element {
  const { exportItems } = props;

  const intl = useIntl();

  return (
    <Popover
      content={tippy => (
        <ExportDropdown closeMenu={tippy.hide} exportItems={exportItems} />
      )}
    >
      <ExportButton
        type="button"
        appearance={ButtonAppearance.Primary}
        inverted
        noShadow
        aria-label={intl.formatMessage({ id: 'export.season.button' })}
        title={intl.formatMessage({ id: 'export.season.button' })}
        data-cy="export-dropdown"
      >
        <Icon size={IconSize.s14}>
          <ExternalIcon name="Download" />
        </Icon>
        &nbsp;
        <Icon size={IconSize.s12}>
          <ExternalIcon name="ChevronDown" />
        </Icon>
      </ExportButton>
    </Popover>
  );
}
