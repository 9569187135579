import { Button, ButtonAppearance } from '@yarmill/components';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { trackCopyButtonClick } from '../google-analytics/utils';

import { useDiaryStore } from '../diary/diary-store-context';
import { useLayer } from '../layer-manager/hooks';
import { Copy } from '../copy';
import { LayerPortal } from '../layer-manager/layer-portal';

const StyledCopyButton = styled.div`
  @media (max-width: 990px) {
    width: 100%;
    margin-bottom: 7px;

    button {
      width: 100%;
    }
  }

  @media print {
    display: none;
  }
`;

function InternalCopyButton(): JSX.Element {
  const diaryStore = useDiaryStore();
  const viewType = diaryStore.viewType;
  const layer = useLayer('full-screen', {
    disableFocusScroll: true
  });

  const onClick = useCallback((): void => {
    trackCopyButtonClick();
    if (!layer.isOpened) {
      layer.open();
    }
  }, [layer]);

  const isGoals = viewType === 'goals' || viewType === 'seasonGoals';

  return (
    <>
      <LayerPortal
        layerHandle={layer}
        getContent={layer => <Copy layer={layer} />}
      />
      <StyledCopyButton>
        <Button
          noShadow
          appearance={ButtonAppearance.Primary}
          onClick={onClick}
          wide
          data-cy="copy-button"
        >
          {isGoals ? (
            <FormattedMessage id="navbar.copyButton.goals" />
          ) : (
            <FormattedMessage id="navbar.copyButton.plan" />
          )}
        </Button>
      </StyledCopyButton>
    </>
  );
}

export const CopyButton = observer(InternalCopyButton);
