import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

interface PlaceholderOptions {
  readonly placeholder: string;
}

function createPlaceholderElement(text: string) {
  const element = document.createElement('div');
  element.className = 'richtext-placeholder';
  element.innerHTML = text;
  return element;
}

export const Placeholder = Extension.create<PlaceholderOptions>({
  name: 'placeholder',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('placeholder'),
        props: {
          decorations: ({ doc }) => {
            if (this.editor.isEmpty && this.options.placeholder) {
              const widget = Decoration.widget(
                2,
                () => createPlaceholderElement(this.options.placeholder),
                {
                  key: this.options.placeholder
                }
              );

              return DecorationSet.create(doc, [widget]);
            }

            return DecorationSet.empty;
          }
        }
      })
    ];
  }
});
