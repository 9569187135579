import { observer } from 'mobx-react-lite';
import { XyChart, TooltipData, AxisValue } from '@yarmill/components';
import { AsyncStatus } from '../api/mobx/request-store';
import { useCallback, useEffect, useMemo } from 'react';
import {
  CategoricalChartReportData,
  CategoricalChartReportStore
} from './types';
import { ReportWrapper } from './report-wrapper';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { ColumnLineChartStore } from './mobx/column-line-chart-store';

const REPORT_HEIGHT = 200;

export interface ChartReportProps {
  readonly report: CategoricalChartReportStore;
  readonly sectionRatio?: number;
  readonly data?: CategoricalChartReportData;
  readonly noReportWrapper?: boolean;
}

export const CategoricalChartReport = observer(function CategoricalChartReport(
  props: ChartReportProps
): JSX.Element {
  const { report, sectionRatio, data: passedData, noReportWrapper } = props;
  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as
      | CategoricalChartReportData
      | undefined);

  const status = passedData ? AsyncStatus.resolved : dataStore?.status;

  const getTooltipData = useCallback(
    (xValue: AxisValue): TooltipData => {
      return report.getTooltipData(xValue, data?.Data || [], 'x');
    },
    [data?.Data, report]
  );

  useEffect(() => {
    report.xy.setConfigOverride(data?.ConfigOverride);
  }, [data?.ConfigOverride, report]);

  const chartElementsConfig = report.chartElementsConfig;
  const axisConfigs = report.xy.axisConfigs;
  const height = report.xy.height;
  const gridLinesConfig = report.xy.gridLinesConfig;
  const noDataMessage = report.xy.noDataMessage;
  const aspectRatio =
    report instanceof ColumnLineChartStore ? report.aspectRatio : undefined;
  const backgroundImage =
    report instanceof ColumnLineChartStore ? report.backgroundImage : undefined;

  const getItem = useCallback(
    (key: string, tickValue: AxisValue) =>
      data?.Data?.find(item => item?.[key] === tickValue),
    [data?.Data]
  );

  const translatedAxisConfigs = useMemo(
    () =>
      axisConfigs.map(axisConfig =>
        axisConfig.translateValues
          ? {
              ...axisConfig,
              formatTick: (tickValue: AxisValue) =>
                report.xy.formatAxisValue(
                  tickValue,
                  axisConfig.axis,
                  {
                    forceString: true
                  },
                  getItem
                )
            }
          : axisConfig
      ),
    [axisConfigs, getItem, report.xy]
  );

  return (
    <ReportWrapper
      hasData={!dataStore || Boolean(data && data.Data.length > 0)}
      height={height ?? REPORT_HEIGHT}
      status={status || AsyncStatus.idle}
      sectionRatio={sectionRatio}
      noDataMessage={noDataMessage}
      aspectRatio={aspectRatio}
      noReportWrapper={noReportWrapper}
    >
      {width => (
        <XyChart
          code={report.code}
          width={width}
          height={
            aspectRatio && width ? width / aspectRatio : height ?? REPORT_HEIGHT
          }
          getTooltipData={getTooltipData}
          configs={chartElementsConfig}
          axisConfigs={translatedAxisConfigs}
          data={data?.Data || []}
          domainX={data?.Metadata?.AxisXValues || []}
          domainY={data?.Metadata?.AxisYDomain || [0, 0]}
          axisYTicks={data?.Metadata?.AxisYValues}
          gridLinesConfig={gridLinesConfig}
          gridLinesRowsTicks={data?.Metadata?.GridLinesRowValues}
          gridLinesColumnsTicks={data?.Metadata?.GridLinesColumnValues}
          hideTooltip={report.hideTooltip}
          backgroundImage={backgroundImage}
        />
      )}
    </ReportWrapper>
  );
});
