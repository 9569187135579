import {
  OVERVIEW_TABLE_CELL_SIZE,
  OVERVIEW_TABLE_LABEL_WIDTH,
  OVERVIEW_TABLE_STICKY_TOP,
  OverviewTableCellData,
  OverviewTableDataFormatter
} from './utils';
import styled, { css } from 'styled-components';

export interface ValueProps {
  value: OverviewTableCellData;
  formatter?: OverviewTableDataFormatter;
  row: number;
  column: number;
  hasRowLabel?: boolean;
  sticky?: boolean;
}

interface ValueWrapperProps {
  row: number;
  column: number;
  hasRowLabel?: boolean;
  sticky?: boolean;
}
const ValueCell = styled.div.attrs<ValueWrapperProps>(({ column, row }) => ({
  style: {
    gridColumn: `${column + 1}  / ${column + 2}`,
    gridRow: `${row + 1} / ${row + 2}`
  }
}))<ValueWrapperProps>`
  padding: 5px;

  justify-items: center;
  align-content: center;
  width: ${OVERVIEW_TABLE_CELL_SIZE}px;
  height: ${OVERVIEW_TABLE_CELL_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ row, column, hasRowLabel }) =>
    row === 0 &&
    column === 0 &&
    hasRowLabel &&
    css`
      width: ${OVERVIEW_TABLE_LABEL_WIDTH}px;
      height: ${OVERVIEW_TABLE_CELL_SIZE}px;
      z-index: 1;
      left: 0;
      top: 0;
      position: sticky;
      background-color: #ffffff;
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: ${OVERVIEW_TABLE_STICKY_TOP};
      height: ${OVERVIEW_TABLE_CELL_SIZE + 26}px;
      z-index: 1;
      padding-top: 31px;

      @media (max-width: 768px) {
        padding-top: 17px;
      }
    `}
`;

const ValueWrapper = styled.div`
  display: inline-block;
  padding: 5px;
`;

const Flag = styled.div`
  border-radius: 20px;
  border: 2px solid #ffffff;
  background-color: #e02020;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
`;

export function Value(props: ValueProps): JSX.Element {
  const { value, formatter, row, column, hasRowLabel, sticky } = props;

  return (
    <ValueCell
      row={row}
      column={column}
      hasRowLabel={hasRowLabel}
      sticky={sticky}
      data-cy="overview-table-value"
      data-row={row}
      data-column={column}
    >
      <ValueWrapper>
        {formatter ? formatter(value.Value, value) : value.Value}
        {value.Flag && <Flag data-cy="flag" />}
      </ValueWrapper>
    </ValueCell>
  );
}
