import { getTicks } from '../helpers';
import { BaseWorkoutChart, BaseWorkoutData } from '../base-workout-chart';
import { ReactNode, useMemo } from 'react';

export interface CgmZone {
  top: number;
  bottom: number;
  name: string;
}

export interface CgmProps {
  data: BaseWorkoutData[];
  domain?: [number, number];
  height: number;
  total: number;
  showTooltip?: boolean;
  showZones?: boolean;
  syncId?: string;
  width: number;
  zones?: CgmZone[];
  id?: string;
  xAxisKey?: 'time' | 'distance';
  formatXAxisTicks?(duration: number): string;
  formatTooltipCursor?(duration: number): string;
  formatTooltip?(item: BaseWorkoutData): ReactNode;
}

export const CGM_RANGE: [number, number] = [30, 220];
export const CGM_ZONES: CgmZone[] = [
  { bottom: 0, top: 59, name: 'very low' },
  { bottom: 60, top: 99, name: 'low' },
  { bottom: 100, top: 149, name: 'in range' },
  { bottom: 150, top: 200, name: 'high' }
];

const CHART_MARGIN = {
  left: 60,
  right: 35,
  bottom: 20,
  top: 30
};

const SAMPLE_MARGIN = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0
};

export function Cgm(props: CgmProps): JSX.Element {
  const {
    showZones,
    total,
    zones = CGM_ZONES,
    domain = CGM_RANGE,
    xAxisKey = 'time'
  } = props;

  const sortedZones = useMemo(
    () =>
      zones.length ? zones.sort((a, b) => a.bottom - b.bottom) : CGM_ZONES,
    [zones]
  );

  const ticks = useMemo(
    () =>
      sortedZones
        .map(zone => zone.bottom)
        .filter(zone => zone > domain[0] && zone < domain[1]),
    [domain, sortedZones]
  );

  return (
    <BaseWorkoutChart
      {...props}
      id="egv"
      yAxisKey="egv"
      domain={props.domain || CGM_RANGE}
      showLeftAxis={showZones}
      showBottomAxis={showZones}
      showGridRows={showZones}
      showArea={!showZones}
      zones={sortedZones || CGM_ZONES}
      yAxisTicks={ticks}
      xAxisTicks={getTicks(xAxisKey, total)}
      strokeDasharray={showZones ? '0,5' : '0,3'}
      strokeWidth={2}
      marginConfig={showZones ? CHART_MARGIN : SAMPLE_MARGIN}
    />
  );
}
